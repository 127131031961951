import URL from '../../../URL';
import SingularRestActionCreator from '../../../utils/SingularRestActionCreator';
import IReport from '../../../interfaces/IReport';
import reportRequests from '../../../requests/report.requests';

const redirect = URL.REPORT.INDEX;
const sRESTCreator = new SingularRestActionCreator<IReport>('REPORT', reportRequests);

export const createReportAction = sRESTCreator.getCreateAction(redirect);
export const fetchReportAction = sRESTCreator.getFetchAction();
