import URL from '../../../../URL';
import { connect } from 'react-redux';
import { IReduxState } from '../../../../store/reducer';
import {
  indexSceneMapDispatchToProps,
  getWrappedIndexScene,
} from '../../../../components/IndexScene/IndexScene';
import IProject from '../../../../interfaces/IProject';
import { fetchProjectsAction } from '../../../../store/actions/projects/projects.actions';

const mapStateToProps = (state: IReduxState) => ({
  data: state.projects.data,
  count: state.projects.count,
});

export const mapDispatchToProps =
  indexSceneMapDispatchToProps<IProject>(
    fetchProjectsAction,
    URL.PROJECTS.EDIT);

export default connect(
  mapStateToProps,
  mapDispatchToProps)(
    getWrappedIndexScene<IProject>('Projektit', URL.PROJECTS.CREATE));
