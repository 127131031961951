import Axios, { AxiosResponse, AxiosPromise } from 'axios';
import cleanRequestBody from './clean-request-body';
import { DataTableLoopbackFilter } from '../components/DataTable/DataTable';

export interface RequestObjectCreatorRequests<T> {
  getAll: (filter?: DataTableLoopbackFilter) => Promise<T[]>;
  count: (filter?: DataTableLoopbackFilter) => Promise<{ count: number }>;
  getById: (id: string) => Promise<T>;
  create: (body: T) => Promise<T>;
  update: (id: string, body: T) => Promise<T>;
  delete: (id: string) => Promise<T>;
}

class RequestObjectCreator<T>{
  url: string;

  constructor(url: string) {
    this.url = url;
  }

  private response<R>(data: R): Promise<R> {
    return Promise.resolve(data);
  }

  private handleArrayResponse(r: AxiosResponse): Promise<T[]> {
    return this.response<T[]>(r.data);
  }

  private handleResponse(r: AxiosResponse): Promise<T> {
    return this.response<T>(r.data);
  }

  private getUrlWithEnd(end: string): string {
    return `${this.url}/${end}`;
  }

  private getRequest(url: string, filter?: DataTableLoopbackFilter): AxiosPromise {
    return Axios.get(url, { params: { filter } });
  }

  getAll(filter?: DataTableLoopbackFilter): Promise<T[]> {
    return this.getRequest(this.url, filter)
      .then(this.handleArrayResponse.bind(this));
  }

  getById(id: string): Promise<T> {
    return this.getRequest(this.getUrlWithEnd(id))
      .then(this.handleResponse.bind(this));
  }

  delete(id: string): Promise<T> {
    return Axios.delete(this.getUrlWithEnd(id))
      .then(this.handleResponse.bind(this));
  }

  create(body: T): Promise<T> {
    return Axios.post(this.url, cleanRequestBody(body))
      .then(this.handleResponse.bind(this));
  }

  update(id: string, body: T): Promise<T> {
    return Axios.patch(this.getUrlWithEnd(id), cleanRequestBody(body))
      .then(this.handleResponse.bind(this));
  }

  count(filter?: DataTableLoopbackFilter): Promise<{ count: number }> {
    return this.getRequest(this.getUrlWithEnd('count'), filter)
      .then((r: AxiosResponse) => this.response<{ count: number }>(r.data));
  }

  getRequests(): RequestObjectCreatorRequests<T> {
    return {
      getAll: (filter?: DataTableLoopbackFilter) => this.getAll(filter),
      getById: (id: string) => this.getById(id),
      create: (body: T) => this.create(body),
      count: (filter?: DataTableLoopbackFilter) => this.count(filter),
      update: (id: string, body: T) => this.update(id, body),
      delete: (id: string) => this.delete(id),
    };
  }
}

export default RequestObjectCreator;
