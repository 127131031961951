import IUser from '../../../interfaces/IUser';
import { pluralGenericRestReducer } from '../../../utils/generic-rest-reducer';
import GenericPluralRedux from '../../../interfaces/GenericPluralRedux';

export interface ITeamMembersRedux extends GenericPluralRedux<IUser> { }

const initState: ITeamMembersRedux = {
  data: [],
  count: 0,
  isFetchLoading: false,
  isCountLoading: false,
};

export default pluralGenericRestReducer<ITeamMembersRedux>('TEAM_MEMBERS', initState);
