import React from 'react';
import { Row, Col, FormGroup, Label, Button } from 'reactstrap';
import { FieldType } from '../../../enums/FieldType';
import { Field, FieldArrayRenderProps } from 'formik';
import Input from '../../../components/FormComponents/Input';

const ReportModelFieldForm = (prop: FieldArrayRenderProps) => (
  <div>
    {prop.form.values.fields.map((_: string, index: number) => (
      <Row key={index}>
        <Col>
          <Input label="Nimi" name={`fields.${index}.name`} />
        </Col>
        <Col>
          <FormGroup>
            <Label>Tyyppi</Label>
            <Field
              name={`fields.${index}.type`}
              type="text"
              component="select"
              id="type-field"
              className="form-control"
              placeholder="Tyyppi"
              label="type"
            >
              <option value={FieldType.TEXT}>Teksti</option>
              <option value={FieldType.TEXTAREA}>Tekstikenttä</option>
            </Field>
          </FormGroup>
        </Col>
        <Col>
          <Button
            type="button"
            id="delete-button"
            onClick={() => prop.remove(index)}
            color="light">
            Poista
          </Button>
        </Col>
      </Row>
    ))}
    <Button
      type="button"
      color="light"
      id="add-button"
      onClick={() => prop.push({ name: 'Esimerkki', type: FieldType.TEXT })}>
      Uusi kenttä
    </Button>
  </div>
);

export default ReportModelFieldForm;
