import React from 'react';
import { Field } from 'formik';
import AbstractBaseWrapper, { AbstractBaseWrapperProps } from './AbstractBaseWrapper';

interface Option {
  value: string;
  text: string;
}

interface Props extends AbstractBaseWrapperProps {
  placeholder?: string;
  options: Option[];
  className?: string;
}

class Select extends AbstractBaseWrapper<Props> {
  getElement(name: string, id: string): JSX.Element {
    const { placeholder, label, className, options } = this.props;
    return (
      <Field
        name={name}
        component="select"
        id={id}
        placeholder={placeholder || label}
        className={className || 'form-control'}>
        {options.map((o: Option) => <option key={o.value} value={o.value}>{o.text}</option>)}
      </Field>
    );
  }
}

export default Select;
