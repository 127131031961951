import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Loading } from '@vivecho/opertr-react-components';
import { Card, CardTitle, CardBody } from 'reactstrap';
import IRouterMatch from '../../../../interfaces/IRouterMatch';
import { fetchReportAction } from '../../../../store/actions/report/report.actions';
import ReportFormat from './components/ReportFormat/ReportFormat';
import { IReduxState } from '../../../../store/reducer';
import { IReportRedux } from '../../../../store/reducers/report/report.reducer';

interface IProps {
  match: IRouterMatch;
  report: IReportRedux;
  fetchReport: Function;
}

class ReportShowScene extends Component<IProps> {
  componentWillMount(): void {
    const { fetchReport } = this.props;
    const reportId = this.props.match.params.id;
    fetchReport(reportId);
  }

  render(): JSX.Element {
    const { report } = this.props;
    if (report.isLoading) return <Loading centeredVertical />;
    return (
      <Card>
        <CardBody>
          <CardTitle tag="h3">{report.name}</CardTitle>
          <CardTitle>
            Raportin hyväksynyt <strong>{report.memberInformationCopy.name}</strong>
          </CardTitle>
          <ReportFormat report={report} />
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = (state: IReduxState) => ({
  report: state.report,
});

export const mapDispatchToProps = (dispatch: Function) => ({
  fetchReport: (id: string) => dispatch(fetchReportAction(id)),
});

export const ReportShowSceneElement = ReportShowScene;
export default connect(mapStateToProps, mapDispatchToProps)(ReportShowScene);
