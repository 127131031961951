import URL from '../../../URL';
import SingularRestActionCreator from '../../../utils/SingularRestActionCreator';
import IUser from '../../../interfaces/IUser';
import {
  INVITE_TEAM_MEMBER_REQUEST,
  INVITE_TEAM_MEMBER_FAIL,
  INVITE_TEAM_MEMBER_SUCCESS,
} from '../../action-types/team-member.action-types';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import Axios, { AxiosError } from 'axios';
import { push } from 'react-router-redux';
import { notifError } from '../notify.actions';
import teamMemberRequests from '../../../requests/team-member.requests';

const redirect = URL.TEAM.INDEX;
const deleteQuestion = 'Haluatko varmasti poistaa käyttäjä?';
const sRESTCreator = new SingularRestActionCreator<IUser>('TEAM_MEMBER', teamMemberRequests);

export const createTeamMemberAction = sRESTCreator.getCreateAction(redirect);
export const updateTeamMemberAction = sRESTCreator.getUpdateAction(redirect);
export const fetchTeamMemberAction = sRESTCreator.getFetchAction();
export const deleteTeamMemberAction = sRESTCreator.getDeleteAction(deleteQuestion, redirect);

const inviteTeamMemberRequestAction = () => ({
  type: INVITE_TEAM_MEMBER_REQUEST,
});

const inviteTeamMemberFailAction = () => ({
  type: INVITE_TEAM_MEMBER_FAIL,
});

const inviteTeamMemberSuccessAction = () => ({
  type: INVITE_TEAM_MEMBER_SUCCESS,
});

export const inviteTeamMemberAction = (email: string) => {
  return (dispatch: ThunkDispatch<void, void, Action>) => {
    dispatch(inviteTeamMemberRequestAction());
    return Axios.post(`${URL.MemberAPI}/invite`, { email })
      .then(() => {
        dispatch(inviteTeamMemberSuccessAction());
        dispatch(push(URL.TEAM.INDEX));
      })
      .catch((e: AxiosError) => {
        dispatch(inviteTeamMemberFailAction());
        dispatch(notifError(e));
      });
  };
};
