import Axios, { AxiosResponse, AxiosError } from 'axios';
import URL from '../../../URL';
import {
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  SET_USER,
} from '../../action-types/user.actionTypes';
import IUser from '../../../interfaces/IUser';
import { notifError } from '../notify.actions';
import IRegisterRequestBody from '../../../interfaces/IRegisterRequestBody';
import { push } from 'react-router-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import * as Sentry from '@sentry/browser';
import isProduction from '../../../utils/is-production';

export const setUserToSentry = (user: IUser) => (scope: Sentry.Scope) => scope.setUser(user);

export const setUserAction = (user: IUser) => {
  Sentry.configureScope(setUserToSentry(user));
  if (isProduction()) {
    window.Intercom('update', { ...user, userId: user.id });
  }
  return {
    type: SET_USER,
    payload: user,
  };
};

const registerRequestAction = () => ({
  type: REGISTER_REQUEST,
});

const registerSuccessAction = (user: IUser) => ({
  type: REGISTER_SUCCESS,
  payload: user,
});

const registerFailAction = () => ({
  type: REGISTER_FAIL,
});

export const registerAction = (body: IRegisterRequestBody) => {
  return (dispatch: ThunkDispatch<void, void, Action>) => {
    dispatch(registerRequestAction());
    return Axios
      .post(`${URL.MemberAPI}/register`, body)
      .then((r: AxiosResponse) => {
        localStorage.setItem(window.TOKEN_LOCAL_STORAGE_KEY, r.data.accessToken.id);
        localStorage.setItem('userId', r.data.user.id);
        dispatch(registerSuccessAction(r.data.user));
        dispatch(push(URL.REPORT_MODULE));
      })
      .catch((err: AxiosError) => {
        dispatch(registerFailAction());
        dispatch(notifError(err));
      });
  };
};
