import React, { Component } from 'react';
import { Card, CardTitle, Loading } from '@vivecho/opertr-react-components';
import { connect } from 'react-redux';
import { fetchReportModelsAction } from
  '../../../../../store/actions/report-models/report-models.actions';
import URL from '../../../../../URL';
import { push } from 'react-router-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Button, Col, Row } from 'reactstrap';
import {
  IReportModelsRedux,
} from '../../../../../store/reducers/report-models/report-models.reducer';
import { IReduxState } from '../../../../../store/reducer';
import IReportModel from '../../../../../interfaces/IReportModel';
import { Action } from 'redux';

interface IMapDispatchToProps {
  onPick(id: string): () => void;
  fetchReportModels(): void;
}

interface IProps extends IMapDispatchToProps {
  reportModels: IReportModelsRedux;
}

class ReportSelectModelScene extends Component<IProps> {
  componentWillMount(): void {
    const { fetchReportModels } = this.props;
    fetchReportModels();
  }

  render(): JSX.Element {
    const { reportModels, onPick } = this.props;
    if (reportModels.isFetchLoading) return <Loading centeredVertical />;
    return (
      <Card>
        <CardTitle>Luo uusi raportti</CardTitle>
        <p>Valitse raportti:</p>
        <Row>
          {reportModels.data.map((model: IReportModel) => (
            <Col colSpan={6} key={model.id}>
              <Card>
                <Button
                  id="pickButton"
                  onClick={onPick(model.id)}
                  className="float-right ml-2">
                  Valitse
                  </Button>
                <h5>{model.name}</h5>
                <p>{model.description}</p>
              </Card>
            </Col>
          ))}
        </Row>
      </Card>
    );
  }
}

export const mapStateToProps = (state: IReduxState) => ({
  reportModels: state.reportModels,
});

export const mapDispatchToProps = (dispatch: ThunkDispatch<void, void, Action>) => ({
  fetchReportModels: () => dispatch(fetchReportModelsAction()),
  onPick: (id: string) => () => dispatch(push(URL.REPORT.FILL.replace(':id', id))),
});

export const ReportSelectModelSceneElement = ReportSelectModelScene;
export default connect(mapStateToProps, mapDispatchToProps)(ReportSelectModelScene);
