import React from 'react';
import moment, { Moment } from 'moment';
import ITimeEvent from '../../interfaces/ITimeEvent';
import GenericForm from '../../components/GenericForm/GenericForm';
import Textarea from '../../components/FormComponents/Textarea';
import Input from '../../components/FormComponents/Input';
import URL from '../../URL';
import RemoteSelect from '../../components/FormComponents/RemoteSelect';
import DatePicker from '../../components/FormComponents/DatePicker';

interface Props {
  onSubmit(timeEvent: ITimeEvent): void;
  initialValues: InitValues;
  backUrl: string;
}

interface InitValues {
  companyId: string;
  memberId: string;
  projectId?: string;
  title?: string;
  description?: string;
  timezoneOffset?: number;
  start?: Moment;
  end?: Moment;
}

const init: ITimeEvent = {
  title: '',
  start: moment().subtract(1, 'hour'),
  end: moment(),
  timezoneOffset: 0,
  description: '',
  id: '',
  memberId: '',
  companyId: '',
  projectId: '',
};

const TimeEventForm = (props: Props) => (
  <GenericForm<ITimeEvent> {...props} initialValues={{ ...init, ...props.initialValues }}>
    <div>
      <Input label="Kuvaus" name="title" />
      <RemoteSelect label="Projekti" name="projectId" getUrl={URL.ProjectAPI} />
      <DatePicker label="Aloitus" name="start" />
      <DatePicker label="Lopetus" name="end" />
      <Textarea label="Muistiinpano" name="description" />
    </div>
  </GenericForm>
);

export default TimeEventForm;
