import IReduxAction from '../../../interfaces/IReduxAction';
import { TIME_REPORT_FETCH_TIME_EVENTS_SUCCESS } from '../../action-types/time-report.action-types';
import ITimeEvent from '../../../interfaces/ITimeEvent';

export interface TimeReportRecord {
  id: string;
  name: string;
  formatedTime: string;
  minutes: number;
}

export interface ITimeReportRedux {
  data: TimeReportRecord[];
  timeEvents: ITimeEvent[];
}

const initState: ITimeReportRedux = {
  data: [],
  timeEvents: [],
};

export default (state: ITimeReportRedux = initState, action: IReduxAction):
  ITimeReportRedux => {
  switch (action.type) {
    case TIME_REPORT_FETCH_TIME_EVENTS_SUCCESS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
