import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  DataTableDefaultSort,
  DataTableColumn,
} from '../../../components/DataTable/DataTable';
import { IReduxState } from '../../../store/reducer';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { getTimeReportAction } from '../../../store/actions/time-report/time-report.actions';
import { Card, CardTitle } from '@vivecho/opertr-react-components';
import { TimeReportRecord } from '../../../store/reducers/time-report/time-report.reducer';
import moment from 'moment';
import { Col, Row } from 'reactstrap';
import ITimeEvent from '../../../interfaces/ITimeEvent';
import TimeReportForm, { TimeReportGroupBy, ITimeReportForm } from './TimeReportForm';
import {
  generalColumns,
  timeEventColumns,
  timeReportColumns,
} from '../../../utils/data-table-columns';
import TimeReportTable from './TimeReportTable';

const timeEventsDefaultSort: DataTableDefaultSort[] = [{
  dataField: 'start',
  order: 'desc',
}];

const reportDefaultSort: DataTableDefaultSort[] = [{
  dataField: 'minutes',
  order: 'asc',
}];

const columns: DataTableColumn[] = [
  generalColumns.name(),
  timeReportColumns.timeAsMinutes(),
];

const timeEventCols: DataTableColumn[] = [
  timeEventColumns.startDate(),
  timeEventColumns.memberName(),
  timeEventColumns.projectName(),
  timeEventColumns.title(),
  timeEventColumns.timeFrame(),
  timeEventColumns.diffAsHours(),
  timeEventColumns.description(),
];

interface Props {
  data: TimeReportRecord[];
  timeEvents: ITimeEvent[];
  getTimeReport(form: ITimeReportForm): void;
}

export class TimeReportScene extends Component<Props> {
  componentDidMount(): void {
    const { getTimeReport } = this.props;
    const query = {
      groupBy: TimeReportGroupBy.MEMBER,
      start: moment().subtract(7, 'days'),
      end: moment(),
    };
    getTimeReport(query);
  }

  render(): JSX.Element {
    const { data, timeEvents, getTimeReport } = this.props;
    return (
      <div>
        <Row>
          <Col>
            <Card>
              <CardTitle>Raportti</CardTitle>
              <Row>
                <Col md={4}>
                  <TimeReportForm onSubmit={getTimeReport} />
                </Col>
                <Col md={8}>
                  <TimeReportTable<TimeReportRecord>
                    id="report-table"
                    data={data}
                    columns={columns}
                    defaultSorted={reportDefaultSort} />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardTitle>Tapahtumat</CardTitle>
              <TimeReportTable<ITimeEvent>
                id="time-event-table"
                data={timeEvents}
                columns={timeEventCols}
                defaultSorted={timeEventsDefaultSort} />
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state: IReduxState) => ({
  data: state.timeReport.data,
  timeEvents: state.timeReport.timeEvents,
});

export const mapDispatchToProps = (dispatch: ThunkDispatch<void, void, Action>) => ({
  getTimeReport: (form: ITimeReportForm) => dispatch(getTimeReportAction(form)),
});

export const ConnectedTimeReportScene =
  connect(mapStateToProps, mapDispatchToProps)(TimeReportScene);
