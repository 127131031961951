import { push } from 'react-router-redux';
import { AxiosError } from 'axios';
import { notifError } from '../store/actions/notify.actions';

export enum RestActionEventTypes {
  REQUEST = 'REQUEST',
  SUCCESS = 'SUCCESS',
  FAIL = 'FAIL',
}

export interface RestActionCreatorResponse<T = undefined> {
  data?: T;
  error?: Error;
}

abstract class AbstractRestActionCreator<RequestObject> {
  context: string;
  requestObject: RequestObject;
  prefix?: string;

  constructor(
    context: string,
    requestObject: RequestObject,
    prefix?: string) {
    this.context = context;
    this.requestObject = requestObject;
    this.prefix = prefix;
  }

  protected getActionType(action: string, event: RestActionEventTypes): string {
    return `@${this.prefix || this.context}:${action}_${this.context}_${event}`;
  }

  private handleSuccessRequest<F>(
    dispatch: Function,
    actionType: string,
    redirectURL?: string): (r: F) => Promise<RestActionCreatorResponse<F>> {
    return (payload: F) => {
      dispatch({
        payload,
        type: this.getActionType(actionType, RestActionEventTypes.SUCCESS),
      });
      if (redirectURL) dispatch(push(redirectURL));
      return Promise.resolve({ data: payload });
    };
  }

  private handleFailRequest<F>(
    dispatch: Function,
    actionType: string): (error: AxiosError) => Promise<RestActionCreatorResponse<F>> {
    return (error: AxiosError) => {
      dispatch({ type: this.getActionType(actionType, RestActionEventTypes.FAIL) });
      dispatch(notifError(error));
      return Promise.resolve({ error });
    };
  }

  protected wrapRequestWithPromiseResolvers<F>(
    request: Promise<F>,
    dispatch: Function,
    actionType: string,
    redirectURL?: string): Promise<RestActionCreatorResponse<F>> {
    dispatch({ type: this.getActionType(actionType, RestActionEventTypes.REQUEST) });
    return request
      .then(this.handleSuccessRequest<F>(dispatch, actionType, redirectURL))
      .catch(this.handleFailRequest<F>(dispatch, actionType));
  }
}

export default AbstractRestActionCreator;
