import ITimeEvent from '../../../interfaces/ITimeEvent';
import moment from 'moment';
import { singularGenericRestReducer } from '../../../utils/generic-rest-reducer';

export interface ITimeEventRedux extends ITimeEvent {
  isLoading: boolean;
}

const initState: ITimeEventRedux = {
  id: '',
  title: '',
  isLoading: false,
  companyId: '',
  memberId: '',
  start: moment(),
  end: moment(),
  timezoneOffset: 0,
  description: '',
  projectId: '',
};

export default singularGenericRestReducer<ITimeEventRedux>('TIME_EVENT', initState);
