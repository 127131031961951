declare global {
  interface Window { API_ENDPOINT: string; }
}

const REPORT_MODULE = '/report-module';
const TIME_MODULE = '/time-module';
const SETTINGS_MODULE = '/settings';

export default {
  REPORT_MODULE,
  TIME_MODULE,
  SETTINGS_MODULE,
  API: window.API_ENDPOINT,
  LOGOUT: '/logout',
  LOGIN: '/login',
  INDEX: '/',
  REPORT: {
    INDEX: `${REPORT_MODULE}/reports`,
    CREATE: `${REPORT_MODULE}/reports/create`,
    FILL: `${REPORT_MODULE}/reports/create/:id`,
    SHOW: `${REPORT_MODULE}/reports/:id`,
  },
  REPORT_MODEL: {
    INDEX: `${REPORT_MODULE}/models`,
    CREATE: `${REPORT_MODULE}/models/create`,
    SHOW: `${REPORT_MODULE}/models/:id`,
    EDIT: `${REPORT_MODULE}/models/:id/edit`,
  },
  TIME: {
    INDEX: `${TIME_MODULE}/time-event`,
    CREATE: `${TIME_MODULE}/time-event/create`,
    SHOW: `${TIME_MODULE}/time-event/:id`,
    EDIT: `${TIME_MODULE}/time-event/:id/edit`,
  },
  TIME_REPORT: `${TIME_MODULE}/reports`,
  PROJECTS: {
    INDEX: `${TIME_MODULE}/projects`,
    CREATE: `${TIME_MODULE}/projects/create`,
    EDIT: `${TIME_MODULE}/projects/:id/edit`,
  },
  TEAM: {
    INDEX: `${SETTINGS_MODULE}/team`,
    INVITE: `${SETTINGS_MODULE}/team/invite`,
    EDIT: `${SETTINGS_MODULE}/team/:id/edit`,
  },
  MemberAPI: `${window.API_ENDPOINT}/Members`,
  ReportAPI: `${window.API_ENDPOINT}/Reports`,
  ReportModelAPI: `${window.API_ENDPOINT}/ReportModels`,
  TimeEventAPI: `${window.API_ENDPOINT}/TimeEvents`,
  ProjectAPI: `${window.API_ENDPOINT}/Projects`,
};
