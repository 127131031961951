import React from 'react';
import mainMenu from '../../../menus/main-menu';
import { ConnectedHeader } from '../Header/Header';

const MainLayout = ({ children }: { children: JSX.Element }) => (
  <div>
    <ConnectedHeader menu={mainMenu} />
    {children}
  </div>
);

export default MainLayout;
