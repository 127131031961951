import ITimeEvent from '../../../interfaces/ITimeEvent';
import GenericPluralRedux from '../../../interfaces/GenericPluralRedux';
import { pluralGenericRestReducer } from '../../../utils/generic-rest-reducer';

export interface ITimeEventsRedux extends GenericPluralRedux<ITimeEvent> { }

const initState: ITimeEventsRedux = {
  isFetchLoading: false,
  isCountLoading: false,
  count: 0,
  data: [],
};

export default pluralGenericRestReducer<ITimeEventsRedux>('TIME_EVENTS', initState);
