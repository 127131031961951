import URL from '../../../URL';
import IProject from '../../../interfaces/IProject';
import SingularRestActionCreator from '../../../utils/SingularRestActionCreator';
import projectRequests from '../../../requests/project.requests';

const redirect = URL.PROJECTS.INDEX;
const deleteQuestion = 'Haluatko varmasti poistaa projektin?';
const sRESTCreator = new SingularRestActionCreator<IProject>('PROJECT', projectRequests);

export const createProjectAction = sRESTCreator.getCreateAction(redirect);
export const updateProjectAction = sRESTCreator.getUpdateAction(redirect);
export const fetchProjectAction = sRESTCreator.getFetchAction();
export const deleteProjectAction = sRESTCreator.getDeleteAction(deleteQuestion, redirect);
