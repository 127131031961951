import React from 'react';
import { Formik } from 'formik';

interface IProps<R> {
  onSubmit(data: R): void;
  initialValues: R;
  children: JSX.Element;
}

const FormikWrapper = function <R>(props: IProps<R>): JSX.Element {
  const { onSubmit, initialValues, children } = props;
  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          {children}
        </form >
      )}
    </Formik>
  );
};

export default FormikWrapper;
