import AbstractRestActionCreator, {
  RestActionCreatorResponse,
} from './AbstractRestActionCreator';
import { DataTableLoopbackFilter } from '../components/DataTable/DataTable';

export interface PluralRestActionCreatorRequest<T> {
  getAll: (filter?: DataTableLoopbackFilter) => Promise<T[]>;
  count: (filter?: DataTableLoopbackFilter) => Promise<{ count: number }>;
}

class PluralRestActionCreator<T>
  extends AbstractRestActionCreator<PluralRestActionCreatorRequest<T>> {
  private getCountAction(): (filter?: DataTableLoopbackFilter)
    => (dispatch: Function) => Promise<RestActionCreatorResponse<{ count: number }>> {
    return (filter?: DataTableLoopbackFilter) => (dispatch: Function) => {
      const actionType = 'COUNT';
      return this.wrapRequestWithPromiseResolvers<{ count: number }>(
        this.requestObject.count(filter),
        dispatch,
        actionType);
    };
  }

  public getFetchAction():
    (filter?: DataTableLoopbackFilter) =>
      (dispatch: Function) =>
        Promise<RestActionCreatorResponse<T[]>> {
    return (filter?: DataTableLoopbackFilter) =>
      (dispatch: Function) => {
        const actionType = 'FETCH';
        return this.wrapRequestWithPromiseResolvers<T[]>(
          this.requestObject.getAll(filter),
          dispatch,
          actionType);
      };
  }

  public getFetchAndCountAction():
    (filter?: DataTableLoopbackFilter) =>
      (dispatch: Function) =>
        Promise<RestActionCreatorResponse<T[]>> {
    return (filter?: DataTableLoopbackFilter) =>
      (dispatch: Function) => {
        dispatch(this.getCountAction()(filter));
        return dispatch(this.getFetchAction()(filter));
      };
  }
}

export default PluralRestActionCreator;
