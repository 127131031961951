import IProject from '../../../interfaces/IProject';
import { singularGenericRestReducer } from '../../../utils/generic-rest-reducer';

export interface IProjectRedux extends IProject {
  isLoading: boolean;
}

const initState: IProjectRedux = {
  id: '',
  name: '',
  isLoading: false,
  companyId: '',
};

export default singularGenericRestReducer<IProjectRedux>('PROJECT', initState);
