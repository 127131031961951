import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { DataTableColumn, DataTableDefaultSort } from '../../../components/DataTable/DataTable';

interface Props<Data> {
  id?: string;
  data: Data[];
  columns: DataTableColumn[];
  defaultSorted: DataTableDefaultSort[];
}

const TimeReportTable = function <T>({ data, columns, defaultSorted }: Props<T>): JSX.Element {
  return (
    <BootstrapTable
      bootstrap4
      keyField="id"
      hover={true}
      bordered={false}
      striped={true}
      data={data}
      columns={columns}
      defaultSorted={defaultSorted}
    />
  );
};

export default TimeReportTable;
