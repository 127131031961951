import React from 'react';
import { Navbar, NavbarBrand, Collapse, NavItem, Nav } from 'reactstrap';
import { NavLink, withRouter } from 'react-router-dom';
import { MenuItem } from '../../../menus/main-menu';
import { connect } from 'react-redux';
import { IReduxState } from '../../../store/reducer';

interface Props {
  isSubHeader?: boolean;
  menu: MenuItem[];
  name?: string;
}

const Header = ({ menu, isSubHeader, name }: Props) => (
  <Navbar sticky="top" expand="md" className={isSubHeader ? 'sub-header' : ''} >
    {!isSubHeader ? (<NavbarBrand>Tuovu</NavbarBrand>) : undefined}
    <Collapse isOpen={true} navbar>
      <Nav navbar>
        {menu.map(s => (
          <NavItem key={s.text}>
            <NavLink exact={s.exact} className="nav-link" to={s.url}>{s.text}</NavLink>
          </NavItem>
        ))}
      </Nav>
    </Collapse>
    {name ? (
      <Nav navbar className="ml-auto">
        <NavItem>
          {name}
        </NavItem>
      </Nav>
    ) : undefined}
  </Navbar>
);

const mapStoreToProps = (store: IReduxState) => ({
  name: store.user.name,
});

export const ConnectedHeader = withRouter<any>(connect(mapStoreToProps)(Header));
export default Header;
