import React, { Component } from 'react';
import { Card, CardTitle, Loading } from '@vivecho/opertr-react-components';
import TimeEventForm from '../../../../forms/TimeEventForm/TimeEventForm';
import { IReduxState } from '../../../../store/reducer';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { connect } from 'react-redux';
import ITimeEvent from '../../../../interfaces/ITimeEvent';
import {
  fetchTimeEventAction,
  updateTimeEventAction,
  deleteTimeEventAction,
} from '../../../../store/actions/time-event/time-event.actions';
import IRouterMatch from '../../../../interfaces/IRouterMatch';
import { ITimeEventRedux } from '../../../../store/reducers/time-event/time-event.reducer';
import { Button } from 'reactstrap';
import URL from '../../../../URL';

interface Props {
  updateTimeEvent(Body: ITimeEvent): void;
  fetchTimeEvent(): void;
  deleteTimeEvent(): void;
  timeEvent: ITimeEventRedux;
  match: IRouterMatch;
}

class TimeEventEditScene extends Component<Props> {
  componentWillMount(): void {
    const { fetchTimeEvent } = this.props;
    fetchTimeEvent();
  }

  render(): JSX.Element {
    const { updateTimeEvent, timeEvent, deleteTimeEvent } = this.props;
    if (!timeEvent.id || timeEvent.isLoading) return <Loading centeredVertical />;
    return (
      <Card>
        <CardTitle>Muokkaa merkintää</CardTitle>
        <TimeEventForm
          onSubmit={updateTimeEvent}
          backUrl={URL.TIME.INDEX}
          initialValues={timeEvent}
        />
        <hr />
        <Button onClick={deleteTimeEvent} id="delete-button" color="danger">Poista</Button>
      </Card>
    );
  }
}

const mapStateToProps = (state: IReduxState) => ({
  timeEvent: state.timeEvent,
});

export const mapDispatchToProps = (dispatch: ThunkDispatch<void, void, Action>, props: Props) => ({
  updateTimeEvent: (body: ITimeEvent) =>
    dispatch(updateTimeEventAction(props.match.params.id, body)),
  fetchTimeEvent: () => dispatch(fetchTimeEventAction(props.match.params.id)),
  deleteTimeEvent: () => dispatch(deleteTimeEventAction(props.match.params.id)),
});

export const TimeEventEditSceneElement = TimeEventEditScene;
export default connect(mapStateToProps, mapDispatchToProps)(TimeEventEditScene);
