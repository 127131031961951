import React, { Component, ErrorInfo } from 'react';
import * as Sentry from '@sentry/browser';
import { CenterContent } from 'releox-react';
import { Button } from 'reactstrap';
import { Card } from '@vivecho/opertr-react-components';

interface State {
  error: Error | null;
}

interface Props {
  children: JSX.Element;
}

class ErrorBoundary extends Component<Props, State> {
  state: State = { error: null };

  componentDidCatch(error: Error, errorInfo: ErrorInfo & { [key: string]: string }): void {
    this.setState({ error });
    Sentry.withScope((scope) => {
      scope.setExtra('errorInfo', errorInfo);
      Sentry.captureException(error);
    });
  }

  render(): JSX.Element {
    if (this.state.error) {
      return (
        <CenterContent>
          <Card>
            <div className="text-center">
              <h3>Järjestelmässä tapahtui virhe.</h3>
              <Button onClick={() => Sentry.showReportDialog()}>Lähetä viesti kehittäjille</Button>
            </div>
          </Card>
        </CenterContent>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
