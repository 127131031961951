import ITimeEvent from '../../../interfaces/ITimeEvent';
import timeEventRequests from '../../../requests/time-event.requests';
import { DataTableLoopbackFilter } from '../../../components/DataTable/DataTable';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import moment from 'moment';
import 'moment-duration-format';
import { TIME_REPORT_FETCH_TIME_EVENTS_SUCCESS } from '../../action-types/time-report.action-types';
import {
  TimeReportGroupBy,
  ITimeReportForm,
} from '../../../scenes/TimeModule/TimeReport/TimeReportForm';
import IUser from '../../../interfaces/IUser';
import IProject from '../../../interfaces/IProject';
import { TimeReportRecord } from '../../reducers/time-report/time-report.reducer';
import { AxiosError } from 'axios';
import { notifError } from '../notify.actions';

interface IExtendedTimeEvent extends ITimeEvent {
  member?: IUser;
  project?: IProject;
}

interface GroupCalculation {
  [key: string]: number;
}

interface ValidatedTimeEvent extends IExtendedTimeEvent {
  member: IUser;
  project: IUser;
}

enum GroupByObjectKeys {
  MEMBER = 'member',
  PROJECT = 'project',
}

const include = ['member', 'project'];
export const UNASSIGNED_VALUE = 'empty';

export class TimeReportAction {
  formData: ITimeReportForm;
  dispatch: ThunkDispatch<void, void, Action>;

  constructor(dispatch: ThunkDispatch<void, void, Action>, form: ITimeReportForm) {
    this.formData = form;
    this.dispatch = dispatch;
  }

  private getGroupCalculation(timeEvents: ITimeEvent[]): GroupCalculation {
    const { groupBy } = this.formData;
    const groupCalculation: GroupCalculation = {};
    timeEvents.map((timeEvent) => {
      const difference = timeEvent.end.diff(timeEvent.start, 'minutes');
      const key = timeEvent[groupBy] || UNASSIGNED_VALUE;
      if (groupCalculation[key] === undefined) return groupCalculation[key] = difference;
      return groupCalculation[key] += difference;
    });
    return groupCalculation;
  }

  private getDurationFromMinutes(minutes: number): string {
    return moment.duration(minutes, 'minutes').format('hh:mm:ss', { trim: false });
  }

  private findTimeEvent(id: string, timeEvents: IExtendedTimeEvent[]): ValidatedTimeEvent {
    const { groupBy } = this.formData;
    const find = (timeEvent: any) => timeEvent[groupBy] === id;
    return timeEvents.find(find) as ValidatedTimeEvent;
  }

  private mapGroupCalculationToTimeReportRecord(
    groupCalculation: GroupCalculation,
    timeEvents: IExtendedTimeEvent[],
    groupBy: TimeReportGroupBy): (id: string) => TimeReportRecord {
    return (id: string) => {
      const formatedTime = this.getDurationFromMinutes(groupCalculation[id]);
      const dataObject: TimeReportRecord = {
        formatedTime,
        id: UNASSIGNED_VALUE,
        name: UNASSIGNED_VALUE,
        minutes: groupCalculation[id],
      };
      if (id !== UNASSIGNED_VALUE) {
        const timeEvent = this.findTimeEvent(id, timeEvents);
        let key = GroupByObjectKeys.MEMBER;
        if (groupBy === TimeReportGroupBy.PROJECT) key = GroupByObjectKeys.PROJECT;
        if (!timeEvent[key]) throw new Error('Missing group by object');
        dataObject.id = timeEvent[key].id;
        dataObject.name = timeEvent[key].name;
      }
      return dataObject;
    };
  }

  private remapGroupCalculationToArray(
    groupCalculation: GroupCalculation, timeEvents: ITimeEvent[]): TimeReportRecord[] {
    const { groupBy } = this.formData;
    return Object
      .keys(groupCalculation)
      .map(this.mapGroupCalculationToTimeReportRecord(groupCalculation, timeEvents, groupBy));
  }

  public getEvents(filter: DataTableLoopbackFilter): Promise<ITimeEvent[]> {
    return timeEventRequests.getAll(filter);
  }

  public calculateResult(timeEvents: ITimeEvent[]): TimeReportRecord[] {
    const groupCalculation = this.getGroupCalculation(timeEvents);
    return this.remapGroupCalculationToArray(groupCalculation, timeEvents);
  }

  public buildSearchQuery(): DataTableLoopbackFilter {
    const { start, end, memberId, projectId } = this.formData;
    const query: DataTableLoopbackFilter = {
      include,
      where: {
        and: [
          { start: { gt: start.toString() } },
          { start: { lt: end.toString() } }],
      },
    };
    if (projectId) query.where.and.push({ projectId });
    if (memberId) query.where.and.push({ memberId });
    return query;
  }

  public static init(form: ITimeReportForm):
    (dispatch: ThunkDispatch<void, void, Action>) =>
      Promise<void> {
    return (dispatch: ThunkDispatch<void, void, Action>) => {
      const timeReportAction = new TimeReportAction(dispatch, form);
      const searchQuery = timeReportAction.buildSearchQuery();
      return timeReportAction.getEvents(searchQuery).then((timeEvents) => {
        const data = timeReportAction.calculateResult(timeEvents);
        dispatch({ type: TIME_REPORT_FETCH_TIME_EVENTS_SUCCESS, payload: { data, timeEvents } });
        return Promise.resolve();
      })
        .catch((err: AxiosError) => {
          dispatch(notifError(err));
        });
    };
  }
}

export const getTimeReportAction = TimeReportAction.init;
