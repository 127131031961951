import React from 'react';
import { FormGroup, Label } from 'reactstrap';

interface Props {
  label: string;
  children: JSX.Element;
}

const InputWrapper = ({ label, children }: Props) => (
  <FormGroup>
    <Label>{label}</Label>
    {children}
  </FormGroup>
);

export default InputWrapper;
