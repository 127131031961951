import React from 'react';
import { FormikValues } from 'formik';
import { DatePicker } from 'antd';
import moment, { Moment } from 'moment';
import AbstractFormikWrapper from '../AbstractFormikWrapper/AbstractFormikWrapper';

interface DateComponentProp {
  field: FormikValues;
  form: { setFieldValue: Function };
}

class DateComponent extends AbstractFormikWrapper<Moment, DateComponentProp> {
  render(): JSX.Element {
    const { field } = this.props;
    return (
      <DatePicker
        showTime
        defaultValue={field.value || moment()}
        allowClear={false}
        onChange={this.onChange.bind(this)}
      />
    );
  }
}

export default DateComponent;
