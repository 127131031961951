import React, { Component } from 'react';
import IUser from '../../../../interfaces/IUser';
import { Card, CardTitle } from '@vivecho/opertr-react-components';
import URL from '../../../../URL';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import DataTable, {
  DataTableColumn,
  DataTableLoopbackFilter,
} from '../../../../components/DataTable/DataTable';
import { ThunkDispatch } from 'redux-thunk';
import {
  fetchTeamMembersAction,
} from '../../../../store/actions/team-members/team-members.actions';
import { Button, Badge } from 'reactstrap';
import { IReduxState } from '../../../../store/reducer';
import { Action } from 'redux';

interface IProps {
  dispatch: Function;
  teamMemberCount: number;
  teamMembers: IUser[];
  onRowClick(ev: string, row: IUser): void;
  redirectToInvitePage(): void;
  fetch(query: DataTableLoopbackFilter): void;
}

class TeamMemberIndexScene extends Component<IProps> {
  static header: DataTableColumn[] = [
    {
      dataField: 'email',
      text: 'Sähköposti',
      sort: true,
    },
    {
      dataField: 'name',
      text: 'Nimi',
      sort: true,
    },
    {
      text: 'Tila',
      dataField: 'isInvitationComplete',
      formatter: (isInvitationComplete: boolean, r: IUser) => {
        const activeBadge = <Badge color="success">Aktiivinen</Badge>;
        const inactiveBadge = <Badge color="secondary">Odottaa</Badge>;
        if (isInvitationComplete || r.isCompanyOwner) return activeBadge;
        return inactiveBadge;
      },
    },
  ];

  render(): JSX.Element {
    const {
      fetch, teamMemberCount, teamMembers, onRowClick, redirectToInvitePage,
    } = this.props;
    return (
      <Card>
        <Button
          color="secondary"
          className="float-right"
          onClick={redirectToInvitePage}>
          Kutsu käyttäjä
        </Button>
        <CardTitle>Käyttäjät</CardTitle>
        <DataTable
          totalSize={teamMemberCount}
          data={teamMembers}
          defaultSorted={{ dataField: 'name', order: 'asc' }}
          onChangeLoopback={fetch}
          onClick={onRowClick}
          columns={TeamMemberIndexScene.header}
        />
      </Card>
    );
  }
}

export const mapStateToProps = (store: IReduxState) => ({
  teamMemberCount: store.teamMembers.count,
  teamMembers: store.teamMembers.data,
});

export const mapDispatchToProps = (dispatch: ThunkDispatch<void, void, Action>) => ({
  fetch: (query?: DataTableLoopbackFilter) => dispatch(fetchTeamMembersAction(query)),
  onRowClick: (_: string, row: IUser) => dispatch(push(URL.TEAM.EDIT.replace(':id', row.id))),
  redirectToInvitePage: () => dispatch(push(URL.TEAM.INVITE)),
});

export const TeamMemberIndexSceneElement = TeamMemberIndexScene;
export default connect(mapStateToProps, mapDispatchToProps)(TeamMemberIndexScene);
