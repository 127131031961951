import URL from '../../../URL';
import IReportModel from '../../../interfaces/IReportModel';
import SingularRestActionCreator from '../../../utils/SingularRestActionCreator';
import reportModelRequests from '../../../requests/report-model.requests';

const redirect = URL.REPORT_MODEL.INDEX;
const deleteQuestion = 'Haluatko varmasti poistaa Pohjan?';
const sRESTCreator =
  new SingularRestActionCreator<IReportModel>('REPORT_MODEL', reportModelRequests);

export const createReportModelAction = sRESTCreator.getCreateAction(redirect);
export const updateReportModelAction = sRESTCreator.getUpdateAction(redirect);
export const fetchReportModelAction = sRESTCreator.getFetchAction();
export const deleteReportModelAction = sRESTCreator.getDeleteAction(deleteQuestion, redirect);
