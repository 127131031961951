import React from 'react';
import IRegisterRequestBody from '../../interfaces/IRegisterRequestBody';
import GenericForm from '../../components/GenericForm/GenericForm';
import Input, { InputTypes } from '../../components/FormComponents/Input';

interface IProps {
  onSubmit(body: IRegisterRequestBody): void;
  initialValues?: IRegisterRequestBody;
  backUrl: string;
}

const initVal: IRegisterRequestBody = {
  user: {
    name: '',
    email: '',
    password: '',
  },
  company: {
    name: '',
  },
};

const RegisterFormComponent = (props: IProps) => {
  return (
    <GenericForm<IRegisterRequestBody>
      {...props}
      initialValues={{ ...initVal, ...props.initialValues }}>
      <div>
        <Input label="Yritys" name="company.name" />
        <Input label="Nimi" name="user.name" />
        <Input label="Sähköposti" name="user.email" type={InputTypes.EMAIL} />
        <Input label="Salasana" name="user.password" type={InputTypes.PASSWORD} />
      </div>
    </GenericForm>
  );
};

export default RegisterFormComponent;
