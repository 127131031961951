import { Component } from 'react';
import { FormikValues } from 'formik';

export interface AbstractFormikWrapperProps {
  field: FormikValues;
  form: { setFieldValue: Function };
}

class AbstractFormikWrapper<V, P> extends Component<P & AbstractFormikWrapperProps> {
  onChange(value: V): void {
    const { form, field } = this.props;
    form.setFieldValue(field.name, value);
  }
}

export default AbstractFormikWrapper;
