import React, { Component } from 'react';
import { Card, CardTitle, Loading } from '@vivecho/opertr-react-components';
import ProjectForm from '../../../../forms/ProjectForm/ProjectForm';
import { IReduxState } from '../../../../store/reducer';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { connect } from 'react-redux';
import IProject from '../../../../interfaces/IProject';
import { fetchProjectAction, updateProjectAction, deleteProjectAction }
  from '../../../../store/actions/project/project.actions';
import IRouterMatch from '../../../../interfaces/IRouterMatch';
import { IProjectRedux } from '../../../../store/reducers/project/project.reducer';
import { Button } from 'reactstrap';
import URL from '../../../../URL';

interface Props {
  deleteProject(): void;
  updateProject(Body: IProject): void;
  fetchProject(): void;
  project: IProjectRedux;
  match: IRouterMatch;
}

class ProjectEditScene extends Component<Props> {
  componentWillMount(): void {
    const { fetchProject } = this.props;
    fetchProject();
  }

  render(): JSX.Element {
    const { updateProject, project, deleteProject } = this.props;
    if (!project.id || project.isLoading) return <Loading centeredVertical />;
    return (
      <Card>
        <CardTitle>Muokkaa projektia</CardTitle>
        <ProjectForm
          onSubmit={updateProject}
          initialValues={project}
          backUrl={URL.PROJECTS.INDEX}
        />
        <hr />
        <Button onClick={deleteProject} id="delete-button" color="danger">Poista</Button>
      </Card>
    );
  }
}

const mapStateToProps = (state: IReduxState) => ({
  project: state.project,
});

export const mapDispatchToProps = (dispatch: ThunkDispatch<void, void, Action>, props: Props) => ({
  updateProject: (body: IProject) => dispatch(updateProjectAction(props.match.params.id, body)),
  fetchProject: () => dispatch(fetchProjectAction(props.match.params.id)),
  deleteProject: () => dispatch(deleteProjectAction(props.match.params.id)),
});

export const ProjectEditSceneElement = ProjectEditScene;
export default connect(mapStateToProps, mapDispatchToProps)(ProjectEditScene);
