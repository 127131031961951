import React from 'react';
import IUser from '../../interfaces/IUser';
import IReportModel from '../../interfaces/IReportModel';
import ReportFieldForm from './ReportFieldForm/ReportFieldForm';
import { IReportField } from '../../interfaces/IReport';
import { FieldArray } from 'formik';
import GenericForm from '../../components/GenericForm/GenericForm';
import Input from '../../components/FormComponents/Input';

interface IProps {
  backUrl: string;
  onSubmit(data: IBody): void;
  initialValues?: InitValues;
}

interface InitValues {
  name?: string;
  fields?: IReportField[];
  companyId?: string;
  memberId?: string;
  memberInformationCopy?: IUser;
  reportModelId?: string;
  reportModel?: IReportModel;
}

interface IBody {
  name: string;
  fields: IReportField[];
  companyId: string;
  memberId: string;
  memberInformationCopy: IUser;
  reportModelId: string;
  reportModel: IReportModel;
}

const initVal: IBody = {
  name: '',
  fields: [],
  companyId: '',
  memberId: '',
  memberInformationCopy: {
    id: '',
    name: '',
    email: '',
    companyId: '',
    isCompanyOwner: false,
  },
  reportModelId: '',
  reportModel: {
    id: '',
    name: '',
    description: '',
    fields: [],
    companyId: '',
  },
};

const ReportForm = (props: IProps) => (
  <GenericForm<IBody> {...props} initialValues={{ ...initVal, ...props.initialValues }}>
    <div>
      <Input name="name" label="Nimi" />
      <FieldArray name="fields" render={ReportFieldForm} />
      <hr />
    </div>
  </GenericForm>
);

export default ReportForm;
