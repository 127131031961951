import React, { Component } from 'react';
import InputWrapper from './InputWrapper';

export interface AbstractBaseWrapperProps {
  label: string;
  id?: string;
  name: string;
}

abstract class AbstractBaseWrapper<P> extends Component<P & AbstractBaseWrapperProps> {
  abstract getElement(name: string, id?: string): JSX.Element;

  render(): JSX.Element {
    const { label, id, name } = this.props;
    return (
      <InputWrapper label={label}>
        {this.getElement(name, id)}
      </InputWrapper>
    );
  }
}

export default AbstractBaseWrapper;
