import React from 'react';
import { ThunkDispatch, ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import DataTable, {
  DataTableLoopbackFilter,
  DataTableProps,
} from '../DataTable/DataTable';
import { push } from 'react-router-redux';
import { Card, CardTitle } from '@vivecho/opertr-react-components';
import { Link } from 'react-router-dom';

export const indexSceneMapDispatchToProps = <T extends { id: string }>(
  fetch: (filter: DataTableLoopbackFilter) => ThunkAction<void, void, void, Action>,
  showUrl: string) =>
  (dispatch: ThunkDispatch<void, void, Action>) => ({
    fetch: (filter: DataTableLoopbackFilter) => dispatch(fetch(filter)),
    onRowClick: (_: string, row: T) =>
      dispatch(push(showUrl.replace(':id', row.id))),
  });

export interface IndexSceneProps<T> extends DataTableProps {
  count: number;
  title: string;
  createUrl: string;
  onRowClick(_: string, row: T): void;
  fetch(filter: DataTableLoopbackFilter): void;
}

const IndexScene = <T extends {}>(props: IndexSceneProps<T>) => {
  const { count, onRowClick, fetch, createUrl, title, columns, defaultSorted } = props;
  return (
    <Card>
      <Link
        className="btn btn-secondary float-right"
        to={createUrl}>Uusi</Link>
      <CardTitle>{title}</CardTitle>
      <DataTable
        {...props}
        totalSize={count}
        onChangeLoopback={fetch}
        onClick={onRowClick}
        columns={columns || [{ text: 'Nimi', dataField: 'name' }]}
        defaultSorted={defaultSorted || { dataField: 'name', order: 'asc' }}
      />
    </Card>
  );
};

export const getWrappedIndexScene =
  <T extends {}>(title: string, createUrl: string) => (props: IndexSceneProps<T>) => (
    <IndexScene
      {...props}
      createUrl={createUrl}
      title={title} />
  );

export default IndexScene;
