import React from 'react';
import { connect } from 'react-redux';
import { createReportModelAction } from
  '../../../../store/actions/report-model/report-model.actions';
import { Card } from '@vivecho/opertr-react-components';
import URL from '../../../../URL';
import ReportModelForm from '../../../../forms/ReportModelForm/ReportModelForm';
import { IReduxState } from '../../../../store/reducer';
import IReportModel, { IReportModelRequestBody } from '../../../../interfaces/IReportModel';

interface Props {
  createReportModel(body: IReportModelRequestBody): void;
  companyId: string;
}

const ReportModelCreateScene = (props: Props) => (
  <Card>
    <ReportModelForm
      onSubmit={props.createReportModel}
      initialValues={{ companyId: props.companyId }}
      backUrl={URL.REPORT_MODEL.INDEX} />
  </Card>
);

export const mapStateToProps = (store: IReduxState) => ({
  companyId: store.user.companyId,
});

export const mapDispatchToProps = (dispatch: Function) => ({
  createReportModel: (body: IReportModel) => dispatch(createReportModelAction(body)),
});

export const ReportModelCreateSceneElement = ReportModelCreateScene;
export default connect(mapStateToProps, mapDispatchToProps)(ReportModelCreateScene);
