import IReport from '../../../../interfaces/IReport';
import URL from '../../../../URL';
import { fetchReportsAction } from '../../../../store/actions/reports/reports.actions';
import { connect } from 'react-redux';
import { IReduxState } from '../../../../store/reducer';
import {
  indexSceneMapDispatchToProps,
  getWrappedIndexScene,
} from '../../../../components/IndexScene/IndexScene';

const mapStateToProps = (state: IReduxState) => ({
  data: state.reports.data,
  count: state.reports.count,
});

export const mapDispatchToProps =
  indexSceneMapDispatchToProps<IReport>(
    fetchReportsAction,
    URL.REPORT.SHOW);

export default connect(
  mapStateToProps,
  mapDispatchToProps)(
    getWrappedIndexScene<IReport>('Raportit', URL.REPORT.CREATE));
