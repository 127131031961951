import IReport from '../../../interfaces/IReport';
import { singularGenericRestReducer } from '../../../utils/generic-rest-reducer';

export interface IReportRedux extends IReport {
  isLoading: boolean;
}

const initState: IReportRedux = {
  id: '',
  name: '',
  isLoading: false,
  memberInformationCopy: {
    name: '',
    email: '',
    isCompanyOwner: false,
    id: '',
    companyId: '',
  },
  fields: [],
  companyId: '',
  memberId: '',
  reportModelId: '',
  reportModel: {
    id: '',
    name: '',
    description: '',
    fields: [],
    companyId: '',
  },
};

export default singularGenericRestReducer<IReportRedux>('REPORT', initState);
