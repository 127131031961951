import React from 'react';
import AsyncSelectWrapper from '../AsyncSelect/AsyncSelectWrapper';
import { Field } from 'formik';
import AbstractBaseWrapper, { AbstractBaseWrapperProps } from './AbstractBaseWrapper';

interface Props extends AbstractBaseWrapperProps {
  getUrl: string;
}

class RemoteSelect extends AbstractBaseWrapper<Props> {
  getElement(name: string, id: string): JSX.Element {
    const { getUrl } = this.props;
    return <Field name={name} id={id} getUrl={getUrl} component={AsyncSelectWrapper} />;
  }
}

export default RemoteSelect;
