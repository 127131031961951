export const INVITE_TEAM_MEMBER_REQUEST = '@TEAM_MEMBER:INVITE_TEAM_MEMBER_REQUEST';
export const INVITE_TEAM_MEMBER_FAIL = '@TEAM_MEMBER:INVITE_TEAM_MEMBER_FAIL';
export const INVITE_TEAM_MEMBER_SUCCESS = '@TEAM_MEMBER:INVITE_TEAM_MEMBER_SUCCESS';
export const DELETE_TEAM_MEMBER_REQUEST = '@TEAM_MEMBER:DELETE_TEAM_MEMBER_REQUEST';
export const DELETE_TEAM_MEMBER_FAIL = '@TEAM_MEMBER:DELETE_TEAM_MEMBER_FAIL';
export const DELETE_TEAM_MEMBER_SUCCESS = '@TEAM_MEMBER:DELETE_TEAM_MEMBER_SUCCESS';
export const FETCH_TEAM_MEMBER_REQUEST = '@TEAM_MEMBER:FETCH_TEAM_MEMBER_REQUEST';
export const FETCH_TEAM_MEMBER_FAIL = '@TEAM_MEMBER:FETCH_TEAM_MEMBER_FAIL';
export const FETCH_TEAM_MEMBER_SUCCESS = '@TEAM_MEMBER:FETCH_TEAM_MEMBER_SUCCESS';
export const UPDATE_TEAM_MEMBER_REQUEST = '@TEAM_MEMBER:UPDATE_TEAM_MEMBER_REQUEST';
export const UPDATE_TEAM_MEMBER_FAIL = '@TEAM_MEMBER:UPDATE_TEAM_MEMBER_FAIL';
export const UPDATE_TEAM_MEMBER_SUCCESS = '@TEAM_MEMBER:UPDATE_TEAM_MEMBER_SUCCESS';
