import React from 'react';
import {
  Card,
  CardTitle,
  Loading,
} from '@vivecho/opertr-react-components';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { inviteTeamMemberAction } from '../../../../store/actions/team-member/team-member.actions';
import URL from '../../../../URL';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import TeamMemberInviteForm from '../../../../forms/TeamMemberInviteForm/TeamMemberInviteForm';
import { IReduxState } from '../../../../store/reducer';
import { Action } from 'redux';

interface IBody {
  email: string;
}

interface IProps {
  isLoading: boolean;
  inviteTeamMember(body: IBody): void;
}

const InviteUserScene = (props: IProps) => {
  const { isLoading, inviteTeamMember } = props;
  if (isLoading) return <Loading centeredVertical />;
  return (
    <Row className="justify-content-center">
      <Col xl={6} lg={10} md={12}>
        <Card>
          <CardTitle>Kutsu käyttäjä</CardTitle>
          <TeamMemberInviteForm
            onSubmit={inviteTeamMember}
            backUrl={URL.TEAM.INDEX} />
        </Card>
      </Col>
    </Row>
  );
};

const mapStateToProps = (store: IReduxState) => ({
  isLoading: store.teamMember.isLoading,
});

export const mapDispatchToProps = (dispatch: ThunkDispatch<void, void, Action>) => ({
  inviteTeamMember: ({ email }: IBody) => dispatch(inviteTeamMemberAction(email)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InviteUserScene);
