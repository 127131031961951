import URL from '../URL';

export interface MenuItem {
  text: string;
  url: string;
  exact: boolean;
}

const mainMenu: MenuItem[] = [
  {
    text: 'Raportit',
    url: URL.REPORT.INDEX,
    exact: false,
  },
  {
    text: 'Aikajärjestelmä',
    url: URL.TIME.INDEX,
    exact: false,
  },
  {
    text: 'Käyttäjät',
    url: URL.TEAM.INDEX,
    exact: false,
  },
  {
    text: 'Kirjaudu ulos',
    url: URL.LOGOUT,
    exact: false,
  },
];

export default mainMenu;
