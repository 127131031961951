import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Loading, Card } from '@vivecho/opertr-react-components';
import IRouterMatch from '../../../../../interfaces/IRouterMatch';
import { fetchReportModelAction } from
  '../../../../../store/actions/report-model/report-model.actions';
import IReportModel from '../../../../../interfaces/IReportModel';
import ReportForm from '../../../../../forms/ReportForm/ReportForm';
import URL from '../../../../../URL';
import moment from 'moment';
import { createReportAction } from '../../../../../store/actions/report/report.actions';
import IUser from '../../../../../interfaces/IUser';
import { IReduxState } from '../../../../../store/reducer';
import { Action } from 'redux';
import IReport from '../../../../../interfaces/IReport';

interface IMapDispatchToProps {
  fetchReportModel(): void;
  createReport(body: IReport): void;
}

interface IProps extends IMapDispatchToProps {
  isLoading: boolean;
  reportModel: IReportModel;
  user: IUser;
}

class ReportFillModelScene extends Component<IProps> {
  componentWillMount(): void {
    const { fetchReportModel } = this.props;
    fetchReportModel();
  }

  render(): JSX.Element {
    const { isLoading, reportModel, createReport, user } = this.props;
    const fields = reportModel.fields.map(d => ({ ...d, value: '' }));
    if (isLoading || !reportModel.id) return <Loading centeredVertical />;
    return (
      <Card>
        <ReportForm
          onSubmit={createReport}
          backUrl={URL.REPORT.CREATE}
          initialValues={{
            fields,
            reportModel,
            name: `${reportModel.name} ${moment().format('YYYY-MM-DD')}`,
            reportModelId: reportModel.id,
            memberId: user.id,
            memberInformationCopy: user,
            companyId: user.companyId,
          }}
        />
      </Card>
    );
  }
}

export const mapStateToProps = (store: IReduxState) => ({
  isLoading: store.reportModel.isLoading,
  reportModel: store.reportModel,
  user: store.user,
});

export const mapDispatchToProps = (
  dispatch: ThunkDispatch<void, void, Action>,
  { match }: { match: IRouterMatch },
): IMapDispatchToProps => ({
  fetchReportModel: () => dispatch(fetchReportModelAction(match.params.id)),
  createReport: (body: IReport) => dispatch(createReportAction(body)),
});

export const ReportFillModelSceneElement = ReportFillModelScene;
export default connect(mapStateToProps, mapDispatchToProps)(ReportFillModelScene);
