import React from 'react';
import { Switch, Route } from 'react-router';
import URL from '../URL';
import ModuleLayout from '../components/Layout/ModuleLayout/ModuleLayout';
import TeamMemberInviteScene from
  '../scenes/Settings/TeamSettings/TeamMemberInviteScene/TeamMemberInviteScene';
import TeamMemberIndexScene from
  '../scenes/Settings/TeamSettings/TeamMemberIndexScene/TeamMemberIndexScene';
import TeamMemberEditScene from
  '../scenes/Settings/TeamSettings/TeamMemberEditScene/TeamMemberEditScene';

const SettingsRouter = () => (
  <ModuleLayout>
    <Switch>
      <Route exact path={URL.TEAM.INVITE} component={TeamMemberInviteScene} />
      <Route exact path={URL.TEAM.INDEX} component={TeamMemberIndexScene} />
      <Route exact path={URL.TEAM.EDIT} component={TeamMemberEditScene} />
    </Switch>
  </ModuleLayout>
);

export default SettingsRouter;
