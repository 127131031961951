import Axios, { AxiosResponse, AxiosError } from 'axios';
import URL from '../../../URL';
import { notifError } from '../notify.actions';
import {
  COUNT_REPORTS_FAIL,
  COUNT_REPORTS_SUCCESS,
  COUNT_REPORTS_REQUEST,
  FETCH_REPORTS_REQUEST,
  FETCH_REPORTS_SUCCESS,
  FETCH_REPORTS_FAIL,
} from '../../action-types/reports.action-types';
import { DataTableLoopbackFilter } from '../../../components/DataTable/DataTable';
import IReport from '../../../interfaces/IReport';

const countReportsRequestAction = () => ({
  type: COUNT_REPORTS_REQUEST,
});

const countReportsSuccessAction = (payload: { count: number }) => ({
  payload,
  type: COUNT_REPORTS_SUCCESS,
});

const countReportsFailAction = () => ({
  type: COUNT_REPORTS_FAIL,
});

const countReportsAction = (filter?: DataTableLoopbackFilter) =>
  (dispatch: Function) => {
    dispatch(countReportsRequestAction());
    return Axios.get(`${URL.ReportAPI}/count`, { params: { filter } })
      .then((r: AxiosResponse) => dispatch(countReportsSuccessAction(r.data)))
      .catch((e: AxiosError) => {
        dispatch(countReportsFailAction());
        dispatch(notifError(e));
      });
  };

const fetchReportsRequestAction = () => ({
  type: FETCH_REPORTS_REQUEST,
});

const fetchReportsSuccessAction = (payload: IReport[]) => ({
  payload,
  type: FETCH_REPORTS_SUCCESS,
});

const fetchReportsFailAction = () => ({
  type: FETCH_REPORTS_FAIL,
});

export const fetchReportsAction = (filter?: DataTableLoopbackFilter) =>
  (dispatch: Function) => {
    dispatch(fetchReportsRequestAction());
    dispatch(countReportsAction(filter));
    return Axios.get(URL.ReportAPI, { params: { filter } })
      .then((r: AxiosResponse) => dispatch(fetchReportsSuccessAction(r.data)))
      .catch((e: AxiosError) => {
        dispatch(fetchReportsFailAction());
        dispatch(notifError(e));
      });
  };
