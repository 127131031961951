import React from 'react';
import { Field } from 'formik';
import AbstractBaseWrapper, { AbstractBaseWrapperProps } from './AbstractBaseWrapper';

interface Props extends AbstractBaseWrapperProps {
  rows?: string;
  placeholder?: string;
  className?: string;
}

class Textarea extends AbstractBaseWrapper<Props> {
  getElement(name: string, id: string): JSX.Element {
    const { rows, placeholder, className, label } = this.props;
    return (
      <Field
        name={name}
        id={id}
        component="textarea"
        rows={rows || '6'}
        placeholder={placeholder || label}
        className={className || 'form-control'}
      />
    );
  }
}

export default Textarea;
