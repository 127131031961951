import {
  REGISTER_REQUEST,
  REGISTER_FAIL,
  REGISTER_SUCCESS,
  SET_USER,
} from '../../action-types/user.actionTypes';
import IReduxAction from '../../../interfaces/IReduxAction';
import IUser from '../../../interfaces/IUser';

export interface IUserRedux extends IUser {
  isLoading: boolean;
}

export const initState: IUserRedux = {
  name: '',
  companyId: '',
  email: '',
  id: '',
  isCompanyOwner: false,
  isLoading: false,
};

export default (state: IUserRedux = initState, action: IReduxAction): IUserRedux => {
  switch (action.type) {
    case SET_USER:
      return { ...initState, ...action.payload };
    case REGISTER_REQUEST:
      return { ...state, isLoading: true };
    case REGISTER_FAIL:
      return { ...state, isLoading: false };
    case REGISTER_SUCCESS:
      return { ...initState, ...action.payload };
    default:
      return state;
  }
};
