import React from 'react';
import { FieldType } from '../../../enums/FieldType';
import { FieldArrayRenderProps } from 'formik';
import Input from '../../../components/FormComponents/Input';
import Textarea from '../../../components/FormComponents/Textarea';

interface IField {
  name: string;
  value: string;
  type: string;
}

const ReportFieldForm = (props: FieldArrayRenderProps) => (
  <div>
    {props.form.values.fields.map((field: IField, index: number) => {
      const inputProps = {
        name: `fields.${index}.value`,
        label: field.name,
        key: index,
      };
      if (field.type === FieldType.TEXT) return <Input {...inputProps} />;
      if (field.type === FieldType.TEXTAREA) return <Textarea {...inputProps} />;
      return undefined;
    })}
  </div>
);

export default ReportFieldForm;
