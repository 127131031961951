import URL from '../URL';
import ITimeEvent from '../interfaces/ITimeEvent';
import RequestObjectCreator, { RequestObjectCreatorRequests } from '../utils/RequestObjectCreator';
import timeEventFormatter from '../utils/time-event-formatter';
import { DataTableLoopbackFilter } from '../components/DataTable/DataTable';

const roc = new RequestObjectCreator<ITimeEvent>(URL.TimeEventAPI);

const formatTimeEvent = (timeEvent: ITimeEvent) => Promise.resolve(timeEventFormatter(timeEvent));
const formatTimeEvents = (timeEvents: ITimeEvent[]) =>
  Promise.resolve(timeEvents.map(timeEvent => timeEventFormatter(timeEvent)));

const timeEventRequests: RequestObjectCreatorRequests<ITimeEvent> = {
  getAll: (filter?: DataTableLoopbackFilter) => roc.getAll(filter).then(formatTimeEvents),
  getById: (id: string) => roc.getById(id).then(formatTimeEvent),
  create: (body: ITimeEvent) => roc.create(body).then(formatTimeEvent),
  count: (filter?: DataTableLoopbackFilter) => roc.count(filter),
  update: (id: string, body: ITimeEvent) => roc.update(id, body).then(formatTimeEvent),
  delete: (id: string) => roc.delete(id),
};

export default timeEventRequests;
