import React from 'react';
import { Loading } from '@vivecho/opertr-react-components';
import AuthLayout from 'releox-react/dist/components/AuthLayout';
import { connect } from 'react-redux';
import { registerAction } from '../../../../store/actions/user/user.actions';
import IRegisterRequestBody from '../../../../interfaces/IRegisterRequestBody';
import URL from '../../../../URL';
import RegisterForm from '../../../../forms/RegisterForm/RegisterForm';
import { IReduxState } from '../../../../store/reducer';

interface IProps {
  register(body: IRegisterRequestBody): void;
  isLoading: boolean;
}

const RegisterScene = ({ isLoading, register }: IProps) => {
  if (isLoading) return <Loading centeredVertical />;
  return (
    <AuthLayout>
      <h1>Luo tunnus</h1>
      <RegisterForm
        onSubmit={register}
        backUrl={URL.LOGIN}
      />
    </AuthLayout>
  );
};

export const mapDispatchToProps = (dispatch: Function) => ({
  register: (body: IRegisterRequestBody) => dispatch(registerAction(body)),
});

const mapStateToProps = (state: IReduxState) => ({
  isLoading: state.user.isLoading,
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterScene);
