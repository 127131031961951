import { getErrorMessage } from '@vivecho/opertr-react-components/lib/helpers';
import { actions as notifActions } from 'redux-notifications';

const { notifSend } = notifActions;

export const notifError = (error: Error) => {
  return (dispatch: Function) => dispatch(notifSend({
    message: getErrorMessage(error),
    kind: 'danger',
    dismissAfter: 2000,
  }));
};
