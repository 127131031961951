import React from 'react';
import ReactDOM from 'react-dom'; // tslint:disable-line import-name
import 'bootstrap/dist/css/bootstrap.min.css';
import '@coreui/coreui/dist/css/coreui.min.css';
import '@vivecho/opertr-react-components/css/opertr.css';
import * as Sentry from '@sentry/browser';
import { Provider } from 'react-redux';
import App from './App';
import { composeWithDevTools } from 'redux-devtools-extension';
import * as serviceWorker from './serviceWorker';
import { createStore, applyMiddleware } from 'redux';
import reducer from './store/reducer';
import 'redux-notifications/lib/styles.css';
import 'antd/dist/antd.css';
import './index.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import reduxThunk from 'redux-thunk';
import { Notifs } from 'redux-notifications';
import createHistory from 'history/createBrowserHistory';
import { Router } from 'react-router';
import { routerMiddleware } from 'react-router-redux';
import { createLogger } from 'redux-logger';
import ErrorBoundary from './ErrorBoundary';
import Axios, { AxiosError } from 'axios';
import ReactGA from 'react-ga';
import isProduction from './utils/is-production';

let dsn;

if (isProduction()) {
  dsn = window.SENTRY_DSN;
  ReactGA.initialize('UA-113516606-4');
}

Sentry.init({ dsn });

Axios.interceptors.response.use(
  response => response,
  (error: AxiosError & { response: any }) => {
    Sentry.withScope((scope) => {
      scope.setExtra('response-object', error.response.data.error);
      scope.setLevel(Sentry.Severity.Error);
      Sentry.captureException(new Error(error.response.data.error.message));
    });
    return Promise.reject(error);
  },
);

const history = createHistory();
history.listen((location) => {
  if (isProduction()) {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  }
});

const logger = createLogger({ collapsed: true });

const store = createStore(
  reducer,
  composeWithDevTools(applyMiddleware(reduxThunk, logger, routerMiddleware(history))),
);

ReactDOM.render((
  <Provider store={store}>
    <ErrorBoundary>
      <Router history={history}>
        <App />
      </Router>
    </ErrorBoundary>
    <Notifs store={store} />
  </Provider>
), document.getElementById('root')); // tslint:disable-line align

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
