import React from 'react';
import GenericForm from '../../components/GenericForm/GenericForm';
import Input, { InputTypes } from '../../components/FormComponents/Input';

interface IProps {
  onSubmit(data: IInviteBody): void;
  initialValues?: IInviteBody;
  backUrl: string;
}

interface IInviteBody {
  email: string;
}

const initVal: IInviteBody = {
  email: '',
};

const TeamMemberInviteForm = (props: IProps) => (
  <GenericForm<IInviteBody> {...props} initialValues={{ ...initVal, ...props.initialValues }} >
    <Input
      label="Sähköposti"
      type={InputTypes.EMAIL}
      name="email" />
  </GenericForm>
);

export default TeamMemberInviteForm;
