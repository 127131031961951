import AbstractRestActionCreator, { RestActionCreatorResponse } from './AbstractRestActionCreator';

export interface SingularRestActionCreatorRequests<T> {
  getById: (id: string) => Promise<T>;
  create: (body: T) => Promise<T>;
  update: (id: string, body: T) => Promise<T>;
  delete: (id: string) => Promise<T>;
}

class SingularRestActionCreator<T>
  extends AbstractRestActionCreator<SingularRestActionCreatorRequests<T>> {

  private getCreateActionMethod(
    body: T,
    dispatch: Function,
    redirectURL?: string): Promise<RestActionCreatorResponse<T>> {
    const actionType = 'CREATE';
    return this.wrapRequestWithPromiseResolvers<T>(
      this.requestObject.create(body),
      dispatch,
      actionType,
      redirectURL);
  }

  private getFetchActionMethod(
    id: string,
    dispatch: Function): Promise<RestActionCreatorResponse<T>> {
    const actionType = 'FETCH';
    return this.wrapRequestWithPromiseResolvers(
      this.requestObject.getById(id),
      dispatch,
      actionType);
  }

  private getUpdateActionMethod(id: string, body: T, dispatch: Function, redirectURL?: string):
    Promise<RestActionCreatorResponse<T>> {
    const actionType = 'UPDATE';
    return this.wrapRequestWithPromiseResolvers(
      this.requestObject.update(id, body),
      dispatch,
      actionType,
      redirectURL);
  }

  private getDeleteActionMethod(
    id: string,
    dispatch: Function,
    question: string,
    redirectURL?: string): Promise<RestActionCreatorResponse<T>> {
    if (!confirm(question)) {
      return Promise.resolve({ error: new Error('User didn\'t confirm request') });
    }
    const actionType = 'DELETE';
    return this.wrapRequestWithPromiseResolvers(
      this.requestObject.delete(id),
      dispatch,
      actionType,
      redirectURL);
  }

  getCreateAction(redirectURL?: string): (body: T) =>
    (dispatch: Function) =>
      Promise<RestActionCreatorResponse<T>> {
    return (body: T) =>
      (dispatch: Function) =>
        this.getCreateActionMethod(body, dispatch, redirectURL);
  }

  getFetchAction(): (id: string) => (dispatch: Function) => Promise<RestActionCreatorResponse<T>> {
    return (id: string) => (dispatch: Function) => this.getFetchActionMethod(id, dispatch);
  }

  getUpdateAction(redirectURL?: string):
    (id: string, body: T) =>
      (dispatch: Function) =>
        Promise<RestActionCreatorResponse<T>> {
    return (id: string, body: T) =>
      (dispatch: Function) =>
        this.getUpdateActionMethod(id, body, dispatch, redirectURL);
  }

  getDeleteAction(question: string, redirectURL?: string):
    (id: string) =>
      (dispatch: Function) =>
        Promise<RestActionCreatorResponse<T>> {
    return (id: string) =>
      (dispatch: Function) =>
        this.getDeleteActionMethod(id, dispatch, question, redirectURL);
  }
}

export default SingularRestActionCreator;
