import IReportModel from '../../../interfaces/IReportModel';
import { singularGenericRestReducer } from '../../../utils/generic-rest-reducer';

export interface IReportModelRedux extends IReportModel {
  isLoading: boolean;
}

const initState: IReportModelRedux = {
  id: '',
  name: '',
  companyId: '',
  isLoading: false,
  description: '',
  fields: [],
};

export default singularGenericRestReducer<IReportModelRedux>('REPORT_MODEL', initState);
