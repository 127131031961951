import React from 'react';
import { Container } from 'reactstrap';
import { MenuItem } from '../../../menus/main-menu';
import Header from '../Header/Header';

interface Props {
  children: JSX.Element;
  menu?: MenuItem[];
  fluid?: boolean;
}

const ModuleLayout = ({ children, menu, fluid }: Props) => (
  <div>
    {menu ? <Header menu={menu} isSubHeader /> : undefined}
    <Container fluid={fluid || false} className="mt-4">
      {children}
    </Container>
  </div>
);

export default ModuleLayout;
