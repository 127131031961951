import React from 'react';
import ITimeEvent from '../../../../interfaces/ITimeEvent';
import URL from '../../../../URL';
import { ThunkDispatch } from 'redux-thunk';
import { push } from 'react-router-redux';
import { Button } from 'reactstrap';
import { Card, CardTitle } from '@vivecho/opertr-react-components';
import DataTable, { DataTableLoopbackFilter, DataTableColumn }
  from '../../../../components/DataTable/DataTable';
import { connect } from 'react-redux';
import { IReduxState } from '../../../../store/reducer';
import { Action } from 'redux';
import { Link } from 'react-router-dom';
import { timeEventColumns } from '../../../../utils/data-table-columns';
import { fetchTimeEventsAction } from '../../../../store/actions/time-events/time-events.actions';

interface Props {
  timeEventCount: number;
  userId: string;
  timeEvents: ITimeEvent[];
  redirectToCreate(): void;
  fetch(query: DataTableLoopbackFilter): void;
}

const columns: DataTableColumn[] = [
  timeEventColumns.startDate(),
  timeEventColumns.title(),
  timeEventColumns.projectName(),
  timeEventColumns.timeFrame(),
  timeEventColumns.diffAsHours(),
  {
    text: '',
    dataField: 'projectId',
    formatter: (_, r) => {
      return <Link to={URL.TIME.EDIT.replace(':id', r.id)}><i className="fa fa-cog" /></Link>;
    },
  },
];

const UserTimeEventIndexScene = (props: Props) => {
  const { timeEvents, timeEventCount, fetch, redirectToCreate, userId } = props;
  return (
    <Card>
      <Button
        color="secondary"
        className="float-right"
        onClick={redirectToCreate}>Uusi merkintä</Button>
      <CardTitle>Viimeisimmät tapahtumasi</CardTitle>
      <DataTable
        data={timeEvents}
        query={{ include: ['project'], where: { memberId: userId } }}
        totalSize={timeEventCount}
        onChangeLoopback={fetch}
        columns={columns}
        defaultSorted={{ dataField: 'start', order: 'desc' }}
      />
    </Card>
  );
};

const mapStateToProps = (state: IReduxState) => ({
  timeEvents: state.timeEvents.data,
  userId: state.user.id,
  timeEventCount: state.timeEvents.count,
});

export const mapDispatchToProps = (dispatch: ThunkDispatch<void, void, Action>) => ({
  fetch: (query?: DataTableLoopbackFilter) => dispatch(fetchTimeEventsAction(query)),
  redirectToCreate: () => dispatch(push(URL.TIME.CREATE)),
});

export const UserTimeEventIndexSceneElement = UserTimeEventIndexScene;
export default connect(mapStateToProps, mapDispatchToProps)(UserTimeEventIndexScene);
