import React from 'react';
import IProject from '../../interfaces/IProject';
import GenericForm from '../../components/GenericForm/GenericForm';
import Input from '../../components/FormComponents/Input';

interface Props {
  onSubmit(project: IProject): void;
  backUrl: string;
  initialValues: InitValues;
}

interface InitValues {
  companyId: string;
  name: string;
}

const init: IProject = {
  name: '',
  id: '',
  companyId: '',
};

const ProjectForm = (props: Props) => (
  <GenericForm<IProject> {...props} initialValues={{ ...init, ...props.initialValues }}>
    <Input label="Nimi" name="name" />
  </GenericForm>
);

export default ProjectForm;
