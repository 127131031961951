import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  fetchReportModelAction,
  updateReportModelAction,
  deleteReportModelAction,
} from '../../../../store/actions/report-model/report-model.actions';
import IReportModel from '../../../../interfaces/IReportModel';
import { ThunkDispatch } from 'redux-thunk';
import URL from '../../../../URL';
import IRouterMatch from '../../../../interfaces/IRouterMatch';
import { Loading, Card } from '@vivecho/opertr-react-components';
import CardTitle from 'reactstrap/lib/CardTitle';
import Button from 'reactstrap/lib/Button';
import ReportModelForm from '../../../../forms/ReportModelForm/ReportModelForm';
import { IReduxState } from '../../../../store/reducer';
import { Action } from 'redux';

interface IMapDispatchToProps {
  fetchReportModel(): void;
  updateReportModel(body: IReportModel): void;
  deleteReportModel(): void;
}

interface IProps extends IMapDispatchToProps {
  isLoading: boolean;
  reportModel: IReportModel;
}

class ReportModelEditScene extends Component<IProps> {
  componentWillMount(): void {
    const { fetchReportModel } = this.props;
    fetchReportModel();
  }

  render(): JSX.Element {
    const {
      isLoading, reportModel, updateReportModel, deleteReportModel,
    } = this.props;
    if (isLoading || !reportModel.id) return <Loading centeredVertical />;
    return (
      <Card>
        <CardTitle>Muokkaa pohjaa</CardTitle>
        <ReportModelForm
          backUrl={URL.REPORT_MODEL.INDEX}
          onSubmit={updateReportModel}
          initialValues={reportModel}
        />
        <hr />
        <Button color="danger" id="delete-model" onClick={deleteReportModel}>Poista pohja</Button>
      </Card>
    );
  }
}

export const mapStateToProps = (store: IReduxState) => ({
  isLoading: store.reportModel.isLoading,
  reportModel: store.reportModel,
});

export const mapDispatchToProps = (
  dispatch: ThunkDispatch<void, void, Action>,
  { match }: { match: IRouterMatch },
): IMapDispatchToProps => ({
  fetchReportModel: () => dispatch(fetchReportModelAction(match.params.id)),
  deleteReportModel: () => dispatch(deleteReportModelAction(match.params.id)),
  updateReportModel: (body: IReportModel) =>
    dispatch(updateReportModelAction(match.params.id, body)),
});

export const ReportModelEditSceneElement = ReportModelEditScene;
export default connect(mapStateToProps, mapDispatchToProps)(ReportModelEditScene);
