import URL from '../../../../URL';
import { connect } from 'react-redux';
import { IReduxState } from '../../../../store/reducer';
import {
  indexSceneMapDispatchToProps,
  getWrappedIndexScene,
} from '../../../../components/IndexScene/IndexScene';
import {
  fetchReportModelsAction,
} from '../../../../store/actions/report-models/report-models.actions';
import IReportModel from '../../../../interfaces/IReportModel';

const mapStateToProps = (state: IReduxState) => ({
  data: state.reportModels.data,
  count: state.reportModels.count,
});

export const mapDispatchToProps =
  indexSceneMapDispatchToProps<IReportModel>(
    fetchReportModelsAction,
    URL.REPORT_MODEL.EDIT);

export default connect(
  mapStateToProps,
  mapDispatchToProps)(
    getWrappedIndexScene<IReportModel>('Pohjat', URL.REPORT_MODEL.CREATE));
