import { combineReducers } from 'redux';
import userReducer, { IUserRedux } from './reducers/user/user.reducer';
import { reducer as notifReducer } from 'redux-notifications';
import teamMemberReducer from './reducers/team-member/team-member.reducer';
import reportReducer, { IReportRedux } from './reducers/report/report.reducer';
import { routerReducer, RouterState } from 'react-router-redux';
import teamMembersReducer, {
  ITeamMembersRedux,
} from './reducers/team-members/team-members.reducer';
import reportModelReducer, {
  IReportModelRedux,
} from './reducers/report-model/report-model.reducer';
import reportModelsReducer, {
  IReportModelsRedux,
} from './reducers/report-models/report-models.reducer';
import reportsReducer, { IReportsRedux } from './reducers/reports/reports.reducer';
import timeEventReducer, { ITimeEventRedux } from './reducers/time-event/time-event.reducer';
import timeEventsReducer, { ITimeEventsRedux } from './reducers/time-events/time-events.reducer';
import projectReducer, { IProjectRedux } from './reducers/project/project.reducer';
import projectsReducer, { IProjectsRedux } from './reducers/projects/projects.reducer';
import timeReportReducer, { ITimeReportRedux } from './reducers/time-report/time-report.reducer';

export interface IReduxState {
  user: IUserRedux;
  teamMember: IUserRedux;
  teamMembers: ITeamMembersRedux;
  report: IReportRedux;
  reports: IReportsRedux;
  reportModel: IReportModelRedux;
  reportModels: IReportModelsRedux;
  timeEvent: ITimeEventRedux;
  timeEvents: ITimeEventsRedux;
  project: IProjectRedux;
  projects: IProjectsRedux;
  timeReport: ITimeReportRedux;
  notifs: {};
  routing: RouterState;
}

const reducer = combineReducers({
  user: userReducer,
  teamMember: teamMemberReducer,
  teamMembers: teamMembersReducer,
  report: reportReducer,
  reports: reportsReducer,
  reportModel: reportModelReducer,
  reportModels: reportModelsReducer,
  timeEvent: timeEventReducer,
  timeEvents: timeEventsReducer,
  project: projectReducer,
  projects: projectsReducer,
  timeReport: timeReportReducer,
  notifs: notifReducer,
  routing: routerReducer,
});

export default reducer;
