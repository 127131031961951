import IReduxAction from '../interfaces/IReduxAction';

export function singularGenericRestReducer<T>(
  name: string,
  initState: T,
  prefix?: string): (state: T | undefined, action: IReduxAction) => T {
  return (
    state: T = initState,
    action: IReduxAction): T => {
    switch (action.type) {
      case `@${prefix || name}:CREATE_${name}_REQUEST`:
      case `@${prefix || name}:UPDATE_${name}_REQUEST`:
      case `@${prefix || name}:FETCH_${name}_REQUEST`:
      case `@${prefix || name}:DELETE_${name}_REQUEST`:
        return { ...initState, isLoading: true };

      case `@${prefix || name}:CREATE_${name}_SUCCESS`:
      case `@${prefix || name}:FETCH_${name}_SUCCESS`:
        return { ...initState, ...action.payload, isLoading: false };

      case `@${prefix || name}:DELETE_${name}_SUCCESS`:
      case `@${prefix || name}:UPDATE_${name}_SUCCESS`:
        return { ...initState, isLoading: false };

      case `@${prefix || name}:CREATE_${name}_FAIL`:
      case `@${prefix || name}:UPDATE_${name}_FAIL`:
      case `@${prefix || name}:FETCH_${name}_FAIL`:
      case `@${prefix || name}:DELETE_${name}_FAIL`:
        return { ...state, isLoading: false };

      default:
        return state;
    }
  };
}

export function pluralGenericRestReducer<T>(
  name: string,
  initState: T,
  prefix?: string): (state: T | undefined, action: IReduxAction) => T {
  return (
    state: T = initState,
    action: IReduxAction): T => {
    switch (action.type) {
      case `@${prefix || name}:FETCH_${name}_REQUEST`:
        return { ...state, isFetchLoading: true };
      case `@${prefix || name}:FETCH_${name}_SUCCESS`:
        return { ...state, data: action.payload, isFetchLoading: false };
      case `@${prefix || name}:FETCH_${name}_FAIL`:
        return { ...state, isFetchLoading: false };
      case `@${prefix || name}:COUNT_${name}_REQUEST`:
        return { ...state, isCountLoading: true };
      case `@${prefix || name}:COUNT_${name}_SUCCESS`:
        return { ...state, ...action.payload, isCountLoading: false };
      case `@${prefix || name}:COUNT_${name}_FAIL`:
        return { ...state, isCountLoading: false };
      default:
        return state;
    }
  };
}
