import React from 'react';
import { DataTableColumn } from '../components/DataTable/DataTable';
import ITimeEvent from '../interfaces/ITimeEvent';
import moment, { Moment } from 'moment';
import 'moment-duration-format';
import { TimeReportRecord } from '../store/reducers/time-report/time-report.reducer';
import TooltipShow from '../components/Tooltip/TooltipShow';

export const generalColumns = {
  name: (o?: DataTableColumn) => ({
    dataField: 'name',
    text: 'Nimi',
    sort: true,
    ...o,
  }),
};

export const timeEventColumns = {
  memberName: (o?: DataTableColumn) => ({
    text: 'Käyttäjä',
    dataField: 'member.name',
    sort: true,
    ...o,
  }),
  projectName: (o?: DataTableColumn) => ({
    text: 'Projekti',
    dataField: 'project.name',
    sort: true,
    ...o,
  }),
  title: (o?: DataTableColumn) => ({
    text: 'Kuvaus',
    dataField: 'title',
    ...o,
  }),
  timeFrame: (o?: DataTableColumn) => ({
    text: 'Aikaväli',
    dataField: 'id',
    sort: false,
    formatter: ((_: string, r: ITimeEvent) =>
      `${r.start.format('HH:mm')} - ${r.end.format('HH:mm')}`),
    ...o,
  }),
  diffAsHours: (o?: DataTableColumn) => ({
    text: 'Kesto',
    dataField: 'end',
    sort: false,
    formatter: ((end: Moment, r: ITimeEvent) => {
      const duration = moment.duration(end.diff(r.start));
      const minutes = duration.asMinutes();
      return moment.duration(minutes, 'minutes').format('hh:mm:ss', { trim: false });
    }),
    ...o,
  }),
  startDate: (o?: DataTableColumn) => ({
    text: 'Pvm',
    dataField: 'start',
    sort: true,
    formatter: ((start: Moment) => {
      return start.format('DD.MM.YYYY');
    }),
    ...o,
  }),
  description: (o?: DataTableColumn) => ({
    text: 'Kuvaus',
    dataField: 'description',
    sort: false,
    formatter: ((description: string, r: ITimeEvent) => {
      const descriptionInfo =
        <TooltipShow
          icon="fa fa-info-circle"
          target={r.id}
          description={description}
        />;
      if (!description) return '';
      return descriptionInfo;
    }),
    ...o,
  }),
};

export const timeReportColumns = {
  timeAsMinutes: (o?: DataTableColumn) => ({
    dataField: 'minutes',
    text: 'Kesto',
    sort: true,
    formatter: (_: number, row: TimeReportRecord) => row.formatedTime,
    ...o,
  }),
};
