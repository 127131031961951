import React from 'react';
import {
  authRoutes, LoginScene,
} from 'releox-react';
import DashboardRouter from './routers/DashboardRouter';
import RegisterScene from './scenes/AuthModule/Register/RegisterScene/RegisterScene';
import { Switch, Route } from 'react-router';

authRoutes[0].component = () => <LoginScene registerUrl="/register" />;

const App = () => (
  <Switch>
    <Route path="/register" component={RegisterScene} />
    {authRoutes.map((r: IOpertrMapRouter) =>
      <Route exact key={r.path} path={r.path} component={r.component} />)}
    <Route path="/" component={DashboardRouter} />
  </Switch>
);

export default App;
