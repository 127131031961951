import IProject from '../../../interfaces/IProject';
import GenericPluralRedux from '../../../interfaces/GenericPluralRedux';
import { pluralGenericRestReducer } from '../../../utils/generic-rest-reducer';

export interface IProjectsRedux extends GenericPluralRedux<IProject> { }

const initState: IProjectsRedux = {
  isFetchLoading: false,
  isCountLoading: false,
  count: 0,
  data: [],
};

export default pluralGenericRestReducer<IProjectsRedux>('PROJECTS', initState);
