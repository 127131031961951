import React from 'react';
import { Field } from 'formik';
import DateComponent from '../DateComponent/DateComponent';
import AbstractBaseWrapper, { AbstractBaseWrapperProps } from './AbstractBaseWrapper';

class DatePicker extends AbstractBaseWrapper<AbstractBaseWrapperProps> {
  getElement(name: string, id: string): JSX.Element {
    return <Field name={name} id={id} component={DateComponent} />;
  }
}

export default DatePicker;
