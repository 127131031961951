import React, { Component } from 'react';
import {
  Loading,
  Card,
  CardTitle,
} from '@vivecho/opertr-react-components';
import IUser from '../../../../interfaces/IUser';
import { connect } from 'react-redux';
import {
  fetchTeamMemberAction,
  updateTeamMemberAction,
} from '../../../../store/actions/team-member/team-member.actions';
import URL from '../../../../URL';
import IRouterMatch from '../../../../interfaces/IRouterMatch';
import { Col, Row } from 'reactstrap';
import TeamMemberEditForm from '../../../../forms/TeamMemberEditForm/TeamMemberEditForm';
import { IUserRedux } from '../../../../store/reducers/user/user.reducer';
import { IReduxState } from '../../../../store/reducer';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';

interface IOwnProps {
  match: IRouterMatch;
}

interface IProps extends IOwnProps {
  teamMember: IUserRedux;
  fetchTeamMember(): void;
  updateTeamMember(user: IUser): void;
}

class TeamMemberEditScene extends Component<IProps> {
  componentWillMount(): void {
    const { fetchTeamMember } = this.props;
    fetchTeamMember();
  }

  render(): JSX.Element {
    const { teamMember, updateTeamMember } = this.props;
    if (teamMember.isLoading || !teamMember.id) return <Loading centeredVertical />;
    return (
      <Row className="justify-content-center">
        <Col xl={6} lg={10} md={12} sm={12}>
          <Card>
            <CardTitle>Muokkaa käyttäjää</CardTitle>
            <TeamMemberEditForm
              backUrl={URL.TEAM.INDEX}
              initialValues={teamMember}
              onSubmit={updateTeamMember}
            />
          </Card>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state: IReduxState) => ({
  teamMember: state.teamMember,
});

export const mapDispatchToProps =
  (dispatch: ThunkDispatch<void, void, Action>, ownProps: IOwnProps) => ({
    fetchTeamMember: () => dispatch(fetchTeamMemberAction(ownProps.match.params.id)),
    updateTeamMember: (body: IUser) =>
      dispatch(updateTeamMemberAction(ownProps.match.params.id, body)),
  });

export const TeamMemberEditSceneElement = TeamMemberEditScene;
export default connect(mapStateToProps, mapDispatchToProps)(TeamMemberEditScene);
