import React from 'react';
import { Link } from 'react-router-dom';
import FormikWrapper from './FormikWrapper';
import { Button } from 'reactstrap';

interface IProps<R> {
  backUrl: string;
  onSubmit(data: R): void;
  initialValues: R;
  children: JSX.Element;
}

const GenericForm = function <R>(props: IProps<R>): JSX.Element {
  const { onSubmit, backUrl, initialValues, children } = props;
  return (
    <FormikWrapper initialValues={initialValues} onSubmit={onSubmit}>
      <div>
        {children}
        <Link className="btn btn-light" id="back-button" to={backUrl}>Takaisin</Link>
        <Button type="submit" id="submit-button" className="pull-right">Tallenna</Button>
      </div>
    </FormikWrapper>
  );
};

export default GenericForm;
