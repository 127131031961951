import React from 'react';
import { Switch, Route } from 'react-router';
import URL from '../URL';
import ModuleLayout from '../components/Layout/ModuleLayout/ModuleLayout';
import timeMenu from '../menus/time-menu';
import UserTimeEventIndexScene
  from '../scenes/TimeModule/TimeEvents/UserTimeEventIndexScene/UserTimeEventIndexScene';
import TimeEventCreateScene from
  '../scenes/TimeModule/TimeEvents/TimeEventCreateScene/TimeEventCreateScene';
import TimeEventEditScene from
  '../scenes/TimeModule/TimeEvents/TimeEventEditScene/TimeEventEditScene';
import ProjectCreateScene
  from '../scenes/TimeModule/Projects/ProjectCreateScene/ProjectCreateScene';
import ProjectIndexScene from '../scenes/TimeModule/Projects/ProjectIndexScene/ProjectIndexScene';
import ProjectEditScene from '../scenes/TimeModule/Projects/ProjectEditScene/ProjectEditScene';
import { ConnectedTimeReportScene } from '../scenes/TimeModule/TimeReport/TimeReportScene';

const TimeRouter = () => (
  <ModuleLayout menu={timeMenu}>
    <Switch>
      <Route exact path={URL.TIME.INDEX} component={UserTimeEventIndexScene} />
      <Route exact path={URL.TIME.CREATE} component={TimeEventCreateScene} />
      <Route exact path={URL.TIME.EDIT} component={TimeEventEditScene} />
      <Route exact path={URL.PROJECTS.INDEX} component={ProjectIndexScene} />
      <Route exact path={URL.PROJECTS.CREATE} component={ProjectCreateScene} />
      <Route exact path={URL.PROJECTS.EDIT} component={ProjectEditScene} />
      <Route exact path={URL.TIME_REPORT} component={ConnectedTimeReportScene} />
    </Switch>
  </ModuleLayout>
);

export default TimeRouter;
