import URL from '../URL';

export default [
  {
    text: 'Raportit',
    url: URL.REPORT.INDEX,
    exact: false,
  },
  {
    text: 'Pohjat',
    url: URL.REPORT_MODEL.INDEX,
    exact: false,
  },
];
