import React from 'react';
import { Card, CardTitle } from '@vivecho/opertr-react-components';
import ProjectForm from '../../../../forms/ProjectForm/ProjectForm';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { createProjectAction } from '../../../../store/actions/project/project.actions';
import IProject from '../../../../interfaces/IProject';
import { IReduxState } from '../../../../store/reducer';
import IUser from '../../../../interfaces/IUser';
import URL from '../../../../URL';

interface Props {
  createProject(body: IProject): void;
  user: IUser;
}

const ProjectCreateScene = ({ user, createProject }: Props) => (
  <Card>
    <CardTitle>Lisää projekti</CardTitle>
    <ProjectForm
      onSubmit={createProject}
      backUrl={URL.PROJECTS.INDEX}
      initialValues={{
        companyId: user.companyId,
        name: '',
      }}
    />
  </Card>
);

const mapStateToProps = (store: IReduxState) => ({
  user: store.user,
});

export const mapDispatchToProps = (dispatch: ThunkDispatch<void, void, Action>) => ({
  createProject: (body: IProject) => dispatch(createProjectAction(body)),
});

export const ProjectCreateSceneElement = ProjectCreateScene;
export default connect(mapStateToProps, mapDispatchToProps)(ProjectCreateScene);
