import React from 'react';
import { Switch, Route } from 'react-router';
import URL from '../URL';
import ReportIndexScene from
  '../scenes/ReportModule/Report/ReportIndexScene/ReportIndexScene';
import ReportSelectModelScene from
  '../scenes/ReportModule/Report/ReportCreateScene/ReportSelectModelScene/ReportSelectModelScene';
import ReportFillModelScene from
  '../scenes/ReportModule/Report/ReportCreateScene/ReportFillModelScene/ReportFillModelScene';
import ReportShowScene from
  '../scenes/ReportModule/Report/ReportShowScene/ReportShowScene';
import ReportModelIndexScene from
  '../scenes/ReportModule/ReportModel/ReportModelIndexScene/ReportModelIndexScene';
import ReportModelCreateScene from
  '../scenes/ReportModule/ReportModel/ReportModelCreateScene/ReportModelCreateScene';
import ReportModelEditScene from
  '../scenes/ReportModule/ReportModel/ReportModelEditScene/ReportModelEditScene';
import ModuleLayout from '../components/Layout/ModuleLayout/ModuleLayout';
import reportMenu from '../menus/report-menu';

const ReportRouter = () => (
  <ModuleLayout menu={reportMenu}>
    <Switch>
      <Route exact path={URL.REPORT.INDEX} component={ReportIndexScene} />
      <Route exact path={URL.REPORT.CREATE} component={ReportSelectModelScene} />
      <Route exact path={URL.REPORT.FILL} component={ReportFillModelScene} />
      <Route exact path={URL.REPORT_MODEL.INDEX} component={ReportModelIndexScene} />
      <Route exact path={URL.REPORT_MODEL.CREATE} component={ReportModelCreateScene} />
      <Route exact path={URL.REPORT_MODEL.EDIT} component={ReportModelEditScene} />
      <Route exact path={URL.REPORT.SHOW} component={ReportShowScene} />
    </Switch>
  </ModuleLayout>
);

export default ReportRouter;
