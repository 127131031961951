import URL from '../../../URL';
import ITimeEvent from '../../../interfaces/ITimeEvent';
import SingularRestActionCreator from '../../../utils/SingularRestActionCreator';
import timeEventRequests from '../../../requests/time-event.requests';

const redirect = URL.TIME.INDEX;
const sRESTCreator = new SingularRestActionCreator<ITimeEvent>('TIME_EVENT', timeEventRequests);
const deleteQuestion = 'Haluatko varmasti poistaa merkinnän?';

export const createTimeEventAction = sRESTCreator.getCreateAction(redirect);
export const updateTimeEventAction = sRESTCreator.getUpdateAction(redirect);
export const fetchTimeEventAction = sRESTCreator.getFetchAction();
export const deleteTimeEventAction = sRESTCreator.getDeleteAction(deleteQuestion, redirect);
