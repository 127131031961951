import React from 'react';
import { FormikValues } from 'formik';
import AsyncSelectInput from './AsyncSelectInput';
import AbstractFormikWrapper from '../AbstractFormikWrapper/AbstractFormikWrapper';

interface AsyncSelectProp {
  field: FormikValues;
  getUrl: string;
  placeholder?: string;
  searchFields: string[];
  form: { setFieldValue: Function };
}

interface AsyncSelectWrapperDefaultProps {
  searchFields: string[];
}

class AsyncSelectWrapper extends AbstractFormikWrapper<string, AsyncSelectProp> {
  public static defaultProps: AsyncSelectWrapperDefaultProps = {
    searchFields: ['name'],
  };

  render(): JSX.Element {
    const { field, getUrl, searchFields, placeholder } = this.props;
    return (
      <AsyncSelectInput
        value={field.value}
        getUrl={getUrl}
        onChange={this.onChange.bind(this)}
        placeholder={placeholder}
        searchFields={searchFields}
      />
    );
  }
}

export default AsyncSelectWrapper;
