import React, { Component } from 'react';
import { Tooltip } from 'reactstrap';

export interface TooltipProps {
  target: string;
  description: string;
  icon: string;
}

interface State {
  tooltipOpen: boolean;
}

class TooltipShow extends Component<TooltipProps> {
  state: State = {
    tooltipOpen: false,
  };

  toggle(): void {
    const { tooltipOpen } = this.state;
    this.setState({
      tooltipOpen: !tooltipOpen,
    });
  }

  render(): JSX.Element {
    const { description, target, icon } = this.props;
    const { tooltipOpen } = this.state;
    return (
      <div>
        <i className={icon} id={`id-${target}`} />
        <Tooltip
          placement="left"
          isOpen={tooltipOpen}
          target={`id-${target}`}
          toggle={this.toggle.bind(this)}>
          {description}
        </Tooltip>
      </div>
    );
  }
}

export default TooltipShow;
