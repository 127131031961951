import React, { Component } from 'react';
import {
  AuthMiddleware,
  ValidateUserModelMiddleware,
} from '@vivecho/opertr-react-components';
import { logoutRoutes } from '@vivecho/opertr-react-components/lib/routers';
import { connect } from 'react-redux';
import IUser from '../interfaces/IUser';
import { setUserAction } from '../store/actions/user/user.actions';
import URL from '../URL';
import { ThunkDispatch } from 'redux-thunk';
import { Route, Switch, Redirect } from 'react-router';
import { Action } from 'redux';
import MainLayout from '../components/Layout/MainLayout/MainLayout';
import ReportRouter from './ReportRouter';
import SettingsRouter from './SettingsRouter';
import TimeRouter from './TimeRouter';
import DashboardScene from '../scenes/DashboardModule/DashboardScene';

interface IState {
  user: IUser;
  isLoading: boolean;
}

interface IProps {
  setUser(user: IUser): void;
}

export interface IDashboardRouter extends Component<IProps> {
  successCallback(user: IUser): void;
  onModelUpdateSuccess(): void;
}

class DashboardRouter extends Component<IProps> implements IDashboardRouter {
  state: IState = {
    isLoading: true,
    user: {
      id: '',
      name: '',
      email: '',
      companyId: '',
      isCompanyOwner: false,
    },
  };

  successCallback(user: IUser): void {
    const { setUser } = this.props;
    setUser(user);
    this.setState({ user, isLoading: false });
  }

  onModelUpdateSuccess(): void {
    location.reload();
  }

  render(): JSX.Element {
    const { isLoading, user } = this.state;
    return (
      <AuthMiddleware loading={isLoading} successCallback={this.successCallback.bind(this)}>
        <ValidateUserModelMiddleware
          user={user}
          onUpdateSuccess={this.onModelUpdateSuccess.bind(this)}
        >
          <MainLayout>
            <Switch>
              <Route exact path={URL.INDEX} component={DashboardScene} />
              <Route path={URL.REPORT_MODULE} component={ReportRouter} />
              <Route path={URL.TIME_MODULE} component={TimeRouter} />
              <Route path={URL.SETTINGS_MODULE} component={SettingsRouter} />
              {logoutRoutes.map((r: IOpertrMapRouter) =>
                <Route exact key={r.path} path={r.path} component={r.component} />)}

              <Redirect to={URL.INDEX} />
            </Switch>
          </MainLayout>
        </ValidateUserModelMiddleware>
      </AuthMiddleware>
    );
  }
}

export const mapDispatchToProps = (dispatch: ThunkDispatch<void, void, Action>) => ({
  setUser: (user: IUser) => dispatch(setUserAction(user)),
});

export const DashboardRouterElement = DashboardRouter;
export default connect(null, mapDispatchToProps)(DashboardRouter);
