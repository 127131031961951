import {
  INVITE_TEAM_MEMBER_REQUEST,
  INVITE_TEAM_MEMBER_FAIL,
  INVITE_TEAM_MEMBER_SUCCESS,
  DELETE_TEAM_MEMBER_REQUEST,
  DELETE_TEAM_MEMBER_FAIL,
  DELETE_TEAM_MEMBER_SUCCESS,
  FETCH_TEAM_MEMBER_REQUEST,
  FETCH_TEAM_MEMBER_FAIL,
  FETCH_TEAM_MEMBER_SUCCESS,
  UPDATE_TEAM_MEMBER_REQUEST,
  UPDATE_TEAM_MEMBER_FAIL,
  UPDATE_TEAM_MEMBER_SUCCESS,
} from '../../action-types/team-member.action-types';
import IReduxAction from '../../../interfaces/IReduxAction';
import { IUserRedux } from '../user/user.reducer';

const initState: IUserRedux = {
  id: '',
  name: '',
  companyId: '',
  email: '',
  isCompanyOwner: false,
  isLoading: false,
};

export default (state: IUserRedux = initState, action: IReduxAction): IUserRedux => {
  switch (action.type) {
    case INVITE_TEAM_MEMBER_REQUEST:
    case FETCH_TEAM_MEMBER_REQUEST:
      return { ...initState, isLoading: true };

    case INVITE_TEAM_MEMBER_FAIL:
    case DELETE_TEAM_MEMBER_FAIL:
    case UPDATE_TEAM_MEMBER_FAIL:
    case INVITE_TEAM_MEMBER_SUCCESS:
    case FETCH_TEAM_MEMBER_FAIL:
      return { ...state, isLoading: false };

    case UPDATE_TEAM_MEMBER_REQUEST:
    case DELETE_TEAM_MEMBER_REQUEST:
      return { ...state, isLoading: true };

    case DELETE_TEAM_MEMBER_SUCCESS:
    case FETCH_TEAM_MEMBER_SUCCESS:
    case UPDATE_TEAM_MEMBER_SUCCESS:
      return { ...state, ...action.payload, isLoading: false };

    default:
      return state;
  }
};
