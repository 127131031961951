import IReportModel from '../../../interfaces/IReportModel';
import GenericPluralRedux from '../../../interfaces/GenericPluralRedux';
import { pluralGenericRestReducer } from '../../../utils/generic-rest-reducer';

export interface IReportModelsRedux extends GenericPluralRedux<IReportModel> { }

const initState: IReportModelsRedux = {
  isFetchLoading: false,
  isCountLoading: false,
  data: [],
  count: 0,
};

export default pluralGenericRestReducer<IReportModelsRedux>('REPORT_MODELS', initState);
