import URL from '../URL';

export default [
  {
    text: 'Merkinnät',
    url: URL.TIME.INDEX,
    exact: false,
  },
  {
    text: 'Projektit',
    url: URL.PROJECTS.INDEX,
    exact: false,
  },
  {
    text: 'Raportointi',
    url: URL.TIME_REPORT,
    exact: true,
  },
];
