import React from 'react';
import { Card } from '@vivecho/opertr-react-components';
import { Col, Row, Container } from 'reactstrap';
import { Link } from 'react-router-dom';
import URL from '../../URL';

const dashboardContent = [
  {
    title: 'Raportit',
    url: URL.REPORT.INDEX,
    icon: 'fa fa-check-square',
  },
  {
    title: 'Aikajärjestelmä',
    url: URL.TIME.INDEX,
    icon: 'fa fa-calendar',
  },
];

const DashboardScene = () => (
  <Container className="pt-4">
    <Row>
      {dashboardContent.map(item => (
        <Col key={item.title} md={3} className="DashboardScene-card">
          <Link to={item.url}>
            <Card>
              <h4>{item.title}</h4>
              <div className="icon-container">
                <i className={item.icon} />
              </div>
            </Card>
          </Link>
        </Col>
      ))}
    </Row>
  </Container>
);

export default DashboardScene;
