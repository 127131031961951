import React from 'react';
import IUser from '../../interfaces/IUser';
import GenericForm from '../../components/GenericForm/GenericForm';
import Input from '../../components/FormComponents/Input';

interface IProps {
  onSubmit(data: IUser): void;
  initialValues?: IUser;
  backUrl: string;
}

const initVal: IUser = {
  id: '',
  email: '',
  companyId: '',
  name: '',
  isCompanyOwner: false,
};

const TeamMemberEditForm = (props: IProps) => (
  <GenericForm<IUser> {...props} initialValues={{ ...initVal, ...props.initialValues }} >
    <Input label="Nimi" name="name" />
  </GenericForm>
);

export default TeamMemberEditForm;
