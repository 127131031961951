import React from 'react';
import { Card, CardTitle } from '@vivecho/opertr-react-components';
import TimeEventForm from '../../../../forms/TimeEventForm/TimeEventForm';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { createTimeEventAction } from '../../../../store/actions/time-event/time-event.actions';
import ITimeEvent from '../../../../interfaces/ITimeEvent';
import { IReduxState } from '../../../../store/reducer';
import IUser from '../../../../interfaces/IUser';
import URL from '../../../../URL';

interface Props {
  createTimeEvent(body: ITimeEvent): void;
  user: IUser;
}

const TimeEventCreateScene = ({ user, createTimeEvent }: Props) => (
  <Card>
    <CardTitle>Lisää merkintä</CardTitle>
    <TimeEventForm
      onSubmit={createTimeEvent}
      backUrl={URL.TIME.INDEX}
      initialValues={{
        companyId: user.companyId,
        memberId: user.id,
        timezoneOffset: (new Date()).getTimezoneOffset(),
      }}
    />
  </Card>
);

const mapStateToProps = (store: IReduxState) => ({
  user: store.user,
});

export const mapDispatchToProps = (dispatch: ThunkDispatch<void, void, Action>) => ({
  createTimeEvent: (body: ITimeEvent) => dispatch(createTimeEventAction(body)),
});

export const TimeEventCreateSceneElement = TimeEventCreateScene;
export default connect(mapStateToProps, mapDispatchToProps)(TimeEventCreateScene);
