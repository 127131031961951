import React from 'react';
import ReportModelFieldForm from './ReportModelFieldForm/ReportModelFieldForm';
import { IReportModelField } from '../../interfaces/IReportModel';
import { FieldArray } from 'formik';
import GenericForm from '../../components/GenericForm/GenericForm';
import Input from '../../components/FormComponents/Input';
import Textarea from '../../components/FormComponents/Textarea';

interface IProps {
  onSubmit(data: IBody): void;
  initialValues?: InitValues;
  backUrl: string;
}

interface InitValues {
  name?: string;
  description?: string;
  fields?: IReportModelField[];
  companyId?: string;
}

export interface IBody {
  name: string;
  description: string;
  fields: IReportModelField[];
  companyId: string;
}

const initVal: IBody = {
  name: '',
  description: '',
  fields: [],
  companyId: '',
};

const ReportModelForm = (props: IProps) => {
  return (
    <GenericForm<IBody> {...props} initialValues={{ ...initVal, ...props.initialValues }}>
      <div>
        <Input label="Nimi" name="name" />
        <Textarea label="Kuvaus" name="description" />
        <hr />
        <FieldArray name="fields" render={ReportModelFieldForm} />
        <hr />
      </div>
    </GenericForm>
  );
};

export default ReportModelForm;
