import React from 'react';
import IReport from '../../../../../../interfaces/IReport';

interface Props {
  report: IReport;
}

const ReportFormat = (props: Props) => {
  return (
    <table>
      <tbody>
        {props.report.fields.map((field) => {
          return (
            <tr key={field.name}>
              <td><strong>{field.name}:</strong></td>
              <td>{field.value}</td>
            </tr>
          );
        })}
      </tbody>
    </table>

  );
};

export default ReportFormat;
