import React from 'react';
import FormikWrapper from '../../../components/GenericForm/FormikWrapper';
import { Button } from 'reactstrap';
import moment, { Moment } from 'moment';
import Select from '../../../components/FormComponents/Select';
import URL from '../../../URL';
import DatePicker from '../../../components/FormComponents/DatePicker';
import RemoteSelect from '../../../components/FormComponents/RemoteSelect';

interface Props {
  onSubmit(query: ITimeReportForm): void;
}

export enum TimeReportGroupBy {
  MEMBER = 'memberId',
  PROJECT = 'projectId',
}

export interface ITimeReportForm {
  start: Moment;
  groupBy: TimeReportGroupBy;
  end: Moment;
  memberId?: string;
  projectId?: string;
}

const initValues = {
  groupBy: TimeReportGroupBy.MEMBER,
  start: moment().subtract(7, 'days'),
  end: moment(),
};

const options = [
  { value: TimeReportGroupBy.MEMBER, text: 'Henkilö' },
  { value: TimeReportGroupBy.PROJECT, text: 'Projekti' },
];

const TimeReportForm = ({ onSubmit }: Props) => (
  <FormikWrapper initialValues={initValues} onSubmit={onSubmit}>
    <div>
      <Select
        label="Ryhmitys"
        name="groupBy"
        options={options} />
      <DatePicker label="Aloitus" name="start" />
      <DatePicker label="Lopetus" name="end" />
      <hr />
      <h5>Suodattimet</h5>
      <RemoteSelect label="Projekti" name="projectId" getUrl={URL.ProjectAPI} />
      <RemoteSelect label="Käyttäjä" name="memberId" getUrl={URL.MemberAPI} />
      <Button type="submit" id="submit-button" color="secondary">Päivitä</Button>
    </div>
  </FormikWrapper >
);

export default TimeReportForm;
