import IReduxAction from '../../../interfaces/IReduxAction';
import {
  FETCH_REPORTS_REQUEST,
  FETCH_REPORTS_SUCCESS,
  FETCH_REPORTS_FAIL,
  COUNT_REPORTS_REQUEST,
  COUNT_REPORTS_SUCCESS,
  COUNT_REPORTS_FAIL,
} from '../../action-types/reports.action-types';
import IReport from '../../../interfaces/IReport';
import GenericPluralRedux from '../../../interfaces/GenericPluralRedux';

export interface IReportsRedux extends GenericPluralRedux<IReport> { }

const initState: IReportsRedux = {
  isFetchLoading: false,
  isCountLoading: false,
  count: 0,
  data: [],
};

export default (state: IReportsRedux = initState, action: IReduxAction): IReportsRedux => {
  switch (action.type) {
    case FETCH_REPORTS_REQUEST:
      return { ...state, isFetchLoading: true };
    case FETCH_REPORTS_SUCCESS:
      return { ...state, data: action.payload as IReport[], isFetchLoading: false };
    case FETCH_REPORTS_FAIL:
      return { ...state, isFetchLoading: false };
    case COUNT_REPORTS_REQUEST:
      return { ...state, isCountLoading: true };
    case COUNT_REPORTS_SUCCESS:
      return { ...state, ...action.payload, isCountLoading: false };
    case COUNT_REPORTS_FAIL:
      return { ...state, isCountLoading: false };
    default:
      return state;
  }
};
